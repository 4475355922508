import axios from "axios";
import { ASSET_MANAGEMENT_API, ASSET_MODEL_API } from "config/default.config";
import { commonUtils } from "utils/common.utils";
import {
  ICreateModelRelationReq,
  ICreateModelReq,
} from "./asset-model.interface";

const fetchAllModelsCount = async () => {
  const response = await axios.get(ASSET_MODEL_API.GET_MODELS_COUNT);
  return response.data;
};

const getDomainDetails = async () => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.DOMAIN_DETAILS)
  );
  return response?.data;
};

const getModelById = async (id: number) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.GET_MODEL_BY_ID, id)
  );
  return response?.data;
};

const createModel = async (modelDetails: ICreateModelReq) => {
  const response = await axios.post(
    ASSET_MODEL_API.CREATE_MODEL,
    modelDetails,
    {
      validateStatus: function (status) {
        return status >= 200 && status < 600; // Treat only 2xx status codes as successful
      },
    }
  );
  return response;
};
const updateModel = async (modelDetails: ICreateModelReq) => {
  const response = await axios.put(ASSET_MODEL_API.UPDATE_MODEL, modelDetails, {
    validateStatus: function (status) {
      return status >= 200 && status < 600; // Treat only 2xx status codes as successful
    },
  });
  return response;
};
const fetchAllModels = async () => {
  const response = await axios.get(ASSET_MODEL_API.GET_ALL_MODELS);
  return response?.data;
};

const fetchModelHierarchy = async (modelId: number) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.GET_MODEL_HIERARCHY, modelId)
  );
  return response?.data;
};
const deleteModel = async (modelId: number) => {
  const response = await axios.delete(
    commonUtils.formatString(ASSET_MODEL_API.DELETE_MODEL, modelId)
  );
  return response.data;
};

const checkIfModelRelationsExist = async (modelId: number) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.INITIATE_DELETE_MODEL, modelId)
  );
  return response.data;
};

const checkIfModelInstanceExist = async (modelName: string) => {
  const response = await axios.get(
    ASSET_MANAGEMENT_API.GET_INSTANCE_COUNT + modelName
  );
  return response.data;
};

const createRelation = async (relation: ICreateModelRelationReq) => {
  const response = await axios.post(ASSET_MODEL_API.CREATE_RELATION, relation, {
    validateStatus: function (status) {
      return status >= 200 && status < 600; // Treat only 2xx status codes as successful
    },
  });
  return response;
};

const publishModel = async (domainId: number) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.PUBLISH_MODEL, domainId)
  );
  return response;
};

const setDomain = async (domainId: number) => {
  const response = await axios.post(
    commonUtils.formatString(ASSET_MODEL_API.SET_DOMAIN, domainId)
  );
  return response;
};
const deleteRelation = async (relationId: number) => {
  const response = await axios.delete(
    commonUtils.formatString(ASSET_MODEL_API.DELETE_MODEL_RELATION, relationId)
  );
  return response.data;
};

const getPublishHistory = async (domainId: number) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.GET_PUBLISH_HISTORY, domainId)
  );
  return response.data;
};
export const assetModelService = {
  getDomainDetails,
  getModelById,
  fetchAllModelsCount,
  createModel,
  updateModel,
  getPublishHistory,
  fetchAllModels,
  fetchModelHierarchy,
  deleteModel,
  checkIfModelRelationsExist,
  checkIfModelInstanceExist,
  createRelation,
  publishModel,
  deleteRelation,
  setDomain,
};
