import { NAMING_CONVENSION_FOR_MODEL } from "assets/commons/commons";
import downloadIcon from "assets/images/file_download.svg";
import closeBtn from "assets/images/seqrops_close_icon.svg";
import deleteIcon from "assets/images/seqrops_delete_icon.svg";
import ConfirmationDialog from "components/dialogs/confirmation.dialog";
import DeleteConfirmationDialog from "components/dialogs/delete-confirmation.dialog";
import { staticPropertyValidation } from "components/static-property/static-property-validation";
import { MESSAGES } from "config/default.config";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useForm, useFormState } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import { ICreateModelReq } from "store/asset-model/asset-model.interface";
import {
  setHasChange,
  setIsDirty,
  setLoading,
} from "store/seqr-ops/seqr-ops.slice";
import "./create-model.scss";
import { IExistinModelRelations, IExistingInstanceCount } from "./delete-model";
import ModelDeletionAlert from "./model-deletion-alert";

interface ICreateModelScreenProps {
  showModel: boolean;
  setShowModel: (show: boolean) => void;
  modelDetails:
    | {
        id: number;
        name: string;
        isRootNode: boolean;
        description: string;
        isEquipment: boolean;
        icon: FileList | string;
      }
    | undefined;
}

export interface IModelCRUDForm {
  id: number;
  name: string;
  isRootNode: boolean;
  description: string;
  isEquipment: boolean;
  icon: FileList | string;
}

function CreateModelScreen({
  showModel,
  setShowModel,
  modelDetails,
}: ICreateModelScreenProps) {
  const confirmationPopup = ConfirmationDialog();
  const deleteConfirmationPopup = DeleteConfirmationDialog();
  const dispatch = useAppDispatch();

  const {
    reset,
    setValue,
    setError,
    register,
    handleSubmit,
    control,
    clearErrors,
  } = useForm<IModelCRUDForm>({
    defaultValues: {
      id: 0,
      name: "",
      isRootNode: false,
      description: "",
      isEquipment: false,
      icon: "",
    },
  });
  const [existinModelRelations, setExistingModelRelations] =
    useState<IExistinModelRelations>({
      sourceModelRelations: [],
      targetModelRelations: [],
      modelName: "",
    });
  const [existingInstanceCount, setExistingInstanceCount] =
    useState<IExistingInstanceCount>({
      modelName: "",
      count: 0,
    });
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { isDirty, errors } = useFormState<IModelCRUDForm>({ control });
  const [isDirtyModel, setIsDirtyModel] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const [filePreview, setFilePreview] = useState<{
    url: string;
    referece: string;
    deleted: boolean;
  }>({
    url: "",
    referece: "",
    deleted: true,
  });
  const errorWrap = (errorMsg: string) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };

  const resetAndClose = () => {
    confirmationPopup.closeDialog();
    reset();
    setSelectedFileName("");
    setShowModel(false);
    setIsDirtyModel(false);
  };

  const handleClose = () => {
    if (isDirtyModel) {
      confirmationPopup.onOpenDialog({
        handleClose() {
          resetAndClose();
          setIsDirtyModel(false);
        },
      });
    } else {
      resetAndClose();
    }
  };

  const setSuccess = () => {
    reset();
    setSelectedFileName("");
    setShowModel(false);
    setIsDirtyModel(false);
    setIsDisableButton(false);
    dispatch(setLoading(false));
  };

  const nameValidation = (propertyValues: IModelCRUDForm) => {
    if (
      propertyValues.name.toLowerCase() === "name" ||
      propertyValues.name.toLowerCase() === "description" ||
      propertyValues.name.toLowerCase() === "createdat" ||
      propertyValues.name.toLocaleLowerCase() === "updatedat" ||
      propertyValues.name.toLocaleLowerCase() === "bucket" ||
      propertyValues.name.toLocaleLowerCase() === "edge" ||
      propertyValues.name.toLocaleLowerCase() === "vertex" ||
      propertyValues.name.toLocaleLowerCase() === "delete" ||
      propertyValues.name.toLocaleLowerCase() === "create" ||
      propertyValues.name.toLocaleLowerCase() === "update" ||
      propertyValues.name.toLocaleLowerCase() === "document"
    ) {
      setError("name", {
        type: "custom",
        message: "Reserved name cannot be used, please try a new name",
      });
      return false;
    }
    if (!/^(?!_)(?!.*_$)[A-Za-z0-9_]+$/.test(propertyValues.name)) {
      setError("name", {
        type: "custom",
        message: "Model name must not start or end with underscore",
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (existingInstanceCount.count > 0) {
      handleClose();
      setShowAlert(true);
    }
  }, [existingInstanceCount]);

  const [isDisableButton, setIsDisableButton] = useState(false);

  //handling save
  const handleSave = (propertyValues: IModelCRUDForm) => {
    setIsDisableButton(true);
    // dispatch(setLoading(true));
    dispatch(setIsDirty(false));
    if (!nameValidation(propertyValues)) {
      setIsDisableButton(false);
      dispatch(setLoading(false));
      return;
    }
    const reqBody: ICreateModelReq = {
      id: propertyValues.id,
      defaultName: modelDetails?.name,
      name: propertyValues.name,
      isRootNode: propertyValues.isRootNode,
      domainId: 1,
      description: propertyValues.description,
      isEquipment: propertyValues.isEquipment,
      icon: "",
    };
    const fileInput: any = document.querySelector('input[name="icon"]');

    const fileData: any = new FormData();
    const file = fileInput?.files[0] as File;
    fileData.append("file", file);
    if (file) {
      if (file.size / (1024 * 1024) > 5) {
        setError("icon", {
          type: "custom",
          message: "Please select a file less than 5MB",
        });
        return false;
      }
    }

    if (reqBody.id !== 0) {
      if (modelDetails?.icon !== "") {
        const path = modelDetails?.icon?.toString();

        const filename = path ? path.substring(path.lastIndexOf("/") + 1) : "";
        // reqBody.icon=path?path:"";
        fileData.append("reference", filename);
      }

      const hasExistingFile =
        filePreview.url && !filePreview.deleted ? true : false;
      const hasNewFile = file ? true : false;

      const createNewFile = !hasExistingFile && hasNewFile ? true : false;
      const deleteExistingFile =
        filePreview.deleted && !hasNewFile ? true : false;
      const replaceExistingFile = hasExistingFile && hasNewFile;

      if (createNewFile) {
        dispatch(
          assetModelActions.createIconAndUpdateModel(
            fileData,
            reqBody,
            setError,
            setSuccess,
            setIsDisableButton,
            setLoading
          )
        );
      } else if (deleteExistingFile) {
        dispatch(
          assetModelActions.deleteIconAndUpdateModel(
            reqBody,
            setError,
            setSuccess,
            setIsDisableButton,
            setLoading
          )
        );
      } else if (replaceExistingFile) {
        dispatch(
          assetModelActions.replaceIconAndUpdateModel(
            fileData,
            reqBody,
            setError,
            setSuccess,
            setIsDisableButton,
            setLoading
          )
        );
      } else {
        reqBody.icon = filePreview.url;
        dispatch(
          assetModelActions.updateModel(
            reqBody,
            setError,
            setSuccess,
            setIsDisableButton,
            setExistingInstanceCount,
            setLoading
          )
        );
      }
      // }
    } else {
      fileData.append("reference", "");
      dispatch(
        assetModelActions.createModel(
          reqBody,
          propertyValues.icon[0] ? fileData : undefined,
          setError,
          setSuccess,
          setIsDisableButton,
          setLoading
        )
      );
    }
  };
  const history = useNavigate();
  const isDirtyForm = useAppSelector(
    (state) => state.seqrOps.dirtyState.isDirty
  );
  const hasChange = useAppSelector((state) => state.seqrOps.hasChange);
  const handleFormChange = () => {
    setIsDirtyModel(true);
  };
  // setting values to the fields for updating

  useEffect(() => {
    if (isDirtyForm == false && hasChange == true) {
      setShowModel(true);
      dispatch(setHasChange(false));
      console.log(showModel);
    } else if (isDirtyForm == true && hasChange == true) {
      setShowModel(false);
    }
  }, [isDirtyForm, hasChange]);

  useEffect(() => {
    if (modelDetails?.id !== 0) {
      setValue("id", modelDetails?.id || 0);
      setValue("name", modelDetails?.name || "");
      setValue("isRootNode", modelDetails?.isRootNode || false);
      setValue("description", modelDetails?.description || "");
      setValue("isEquipment", modelDetails?.isEquipment || false);
      setValue("icon", "");

      if (modelDetails?.icon) {
        setFilePreview({
          url: (modelDetails?.icon as string) || "",
          referece: "",
          deleted: false,
        });
      } else {
        setFilePreview({
          ...filePreview,
          deleted: true,
        });
      }
    }
  }, [setValue, modelDetails]);

  return (
    <>
      <div className="main-wrapper">
        <Modal
          show={showModel}
          onHide={handleClose}
          backdrop="static"
          // keyboard={false}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              {modelDetails?.id ? "Update Model" : "Create Model"}
              {!filePreview.deleted && filePreview.url ? (
                <>
                  {/* <label htmlFor="icon">Icon</label><br></br> */}
                  <span className="ms-3 me-3 seqrops-model-icon">
                    <a
                      href={filePreview.url}
                      title={"Download"}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={downloadIcon}
                        alt="seqrops edit button"
                        className=""
                      />
                    </a>
                  </span>
                  <span
                    onClick={() => {
                      deleteConfirmationPopup.onOpenDialog({
                        title: "Delete Confirmation",
                        message: "Are you sure want to delete the icon?",
                        cancelBtn: "No",
                        successBtn: "Yes",
                        handleClose: () => {
                          setIsDirtyModel(true);
                          handleFormChange();
                          setFilePreview({
                            ...filePreview,
                            deleted: true,
                          });
                          confirmationPopup.closeDialog();
                        },
                      });
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="seqrops edit button"
                      className="delete-icon"
                    />
                  </span>
                </>
              ) : null}
            </Modal.Title>
            <button
              className="seqrops-create-model-popup-close-btn"
              onClick={() => {
                handleClose();
              }}
            >
              <img src={closeBtn} alt="" />
            </button>
          </Modal.Header>
          <Modal.Body className="model-body">
            <label className="form-label" htmlFor="modelName">
              Name
            </label>
            <br />
            <input
              className="seqrops-create-model-input-section form-control"
              type="text"
              id="modelName"
              minLength={3}
              maxLength={50}
              {...register("name", {
                required: MESSAGES.MODEL_NAME_REQUIRED,
                pattern: {
                  value: /^(?![0-9!@#$%^&*])\w.*/,
                  message: NAMING_CONVENSION_FOR_MODEL,
                },
                minLength: {
                  value: 3,
                  message: "Model name should contain minimum 3 characters",
                },
                maxLength: {
                  value: 50,
                  message:
                    "Model name should contain only maximum 50 characters",
                },
              })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue("name", e.target.value.replace(/\W/g, ""));
                clearErrors("name");
                handleFormChange();
              }}
              placeholder="Name"
            />

            <>{errorWrap(errors?.name?.message || "")}</>
            <label className="form-label" htmlFor="modelDescription">
              Description
            </label>
            <br />
            <textarea
              className="seqrops-create-model-description-section form-control"
              id="modelDescription"
              rows={5}
              cols={40}
              minLength={3}
              maxLength={250}
              {...register("description", {
                minLength: {
                  value: 3,
                  message:
                    "Model description should contain minimum 3 characters",
                },
                maxLength: {
                  value: 250,
                  message:
                    "Model description should contain only maximum 250 characters",
                },
              })}
              onChange={(e) => {
                setValue("description", e.target.value);
                clearErrors("description");
                handleFormChange();
              }}
              placeholder="Description"
            ></textarea>
            <>{errorWrap(errors?.description?.message || "")}</>

            {!filePreview.deleted && filePreview.url ? null : (
              <>
                <label className="form-label" htmlFor="icon">
                  Icon
                </label>
                <input
                  className="form-control seqrops-create-model-select-file-section"
                  type="file"
                  id="icon"
                  accept={staticPropertyValidation.filetypes.ICON.join(", ")}
                  {...register("icon", {})}
                  onChange={(e: any) => {
                    handleFormChange();
                    const selectedFile = e.target.files[0];
                    setSelectedFileName(selectedFile?.name || "");
                  }}
                />
                <label
                  className="form-control custom-file-label"
                  htmlFor="icon"
                >
                  {selectedFileName || "Please select an icon"}
                </label>
                <>{errorWrap(errors?.icon?.message || "")}</>
              </>
            )}

            <div className="d-flex md-6">
              <div className="d-flex mt-2">
                <input
                  type="checkbox"
                  className="round-checkbox"
                  id="isRootNode"
                  {...register("isRootNode")}
                  onChange={(e: any) => {
                    clearErrors("isRootNode");
                    handleFormChange();
                  }}
                />
                <label htmlFor="isRootNode" className="mx-2 form-label">
                  Set as Root Node
                </label>
              </div>
              <>{errorWrap(errors?.isRootNode?.message || "")}</>

              <div className="d-flex mt-2">
                <input
                  type="checkbox"
                  className="round-checkbox"
                  id="isEquipment"
                  {...register("isEquipment")}
                  onChange={(e: any) => {
                    // setValue("isRootNode", e.target.value);
                    clearErrors("isEquipment");
                    handleFormChange();
                  }}
                />
                <label htmlFor="isEquipment" className="mx-2 form-label">
                  Is Equipment
                </label>
              </div>
              <>{errorWrap(errors?.isEquipment?.message || "")}</>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel-create-model seqrops-btn btn-outline me-2"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              className="seqrops-btn btn-fill"
              onClick={handleSubmit(handleSave)}
              disabled={isDisableButton}
            >
              {modelDetails?.id != null ? "Update Model" : "Create Model"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ModelDeletionAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        existingRelations={existinModelRelations}
        existingInstanceCount={existingInstanceCount}
        setExistingInstanceCount={setExistingInstanceCount}
        setExistingModelRelations={setExistingModelRelations}
      ></ModelDeletionAlert>
    </>
  );
}

export default CreateModelScreen;
