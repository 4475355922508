import { CLICKPOSITION } from "config/default.config";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import { setModelHierarchy } from "store/asset-model/asset-model.slice";
import "./model-tree.scss";
import {
  IModelTreeActions,
  IModelTreeDataItem,
  IModelTreeEvent,
  IModelTreeMap,
} from "./model-tree.types";
import TreeList from "./sub.components/tree-list";

function ModelingTree(props: {
  modelId: number | undefined;
  updateModel: ({ id, name, isRootNode }: IModelTreeEvent) => void;
  deleteModel: ({ id, name, isRootNode }: IModelTreeEvent) => void;
  deleteRelation: (id: number, name: string) => void;
  setIsBackGroundImg: (value: boolean) => void;
}) {
  const dispatch = useAppDispatch();

  const modelHierarchyList = useAppSelector(
    (state) => state.assetModels.modelHierarchy
  );
  const [tree, setTree] = useState<IModelTreeDataItem[]>([]);
  const [toggleMap, setToggleMap] = useState<IModelTreeMap>({});
  const [actionMap, setActionMap] = useState<IModelTreeMap>({});

  useEffect(() => {
    if (props.modelId) {
      dispatch(
        assetModelActions.getModelHierarchy(props.modelId, CLICKPOSITION.NONE)
      );
    }
  }, [dispatch, props.modelId]);

  useEffect(() => {
    if (modelHierarchyList !== undefined) {
      setTree([...modelHierarchyList]);
    }
    if (modelHierarchyList[0]?.edges.length > 0) {
      props.setIsBackGroundImg(true);
    } else {
      props.setIsBackGroundImg(false);
    }
  }, [modelHierarchyList]);

  const toggleOpen = (key: string) => {
    toggleMap[key] = !toggleMap[key];
    setToggleMap({ ...toggleMap });
  };

  const toggleAction = (key: string) => {
    const am = { [key]: true };
    setActionMap({ ...am });
  };

  const addChild = (parent: any) => {
    const newTree = [...tree];
    if (!parent) {
      parent.edges.push({ name: "New Item" });
    }
    setTree(newTree);
  };

  function formatData(
    data: IModelTreeDataItem[],
    keyIndexes: number[],
    subModel: any[]
  ) {
    if (keyIndexes.length === 0) return;
    data.forEach((item: IModelTreeDataItem, index: number) => {
      if (keyIndexes.length === 1) {
        if (index === keyIndexes[0]) {
          item.edges = subModel;
          return item;
        }
      } else if (keyIndexes[keyIndexes.length - 1] === index) {
        keyIndexes.pop();
        return formatData(item.edges, keyIndexes, subModel);
      }
    });
  }

  const getSubModel = async (id: number, key: string) => {
    const keys = key.split("tree-root-")[1].split("-")?.reverse();
    let keyIndexes = keys?.map(Number) || [];

    const treeTmp = JSON.parse(JSON.stringify(tree));
    const sub: any = await assetModelActions.getSubModelHierarchy(id);
    formatData(treeTmp, keyIndexes, sub);
    dispatch(setModelHierarchy(treeTmp));
  };

  const actions: IModelTreeActions = {
    toggleOpen,
    toggleAction,
    addChild,
    getSubModel,
    updateModel: props.updateModel,
    deleteModel: props.deleteModel,
    deleteRelation: props.deleteRelation,
  };
  return (
    <>
      <TreeList
        customKey={"tree-root"}
        tree={tree}
        toggleMap={toggleMap}
        actionMap={actionMap}
        actions={actions}
      />
    </>
  );
}
export default ModelingTree;
