import { Button, Modal } from "react-bootstrap";
import {
  IExistinModelRelations,
  IExistingInstanceCount,
  IModelRelationDetails,
} from "./delete-model";
import "./model-deletion-alert.scss";

interface IModelDeletionAlertProps {
  showAlert: boolean;
  setShowAlert: any;
  existingRelations: IExistinModelRelations;
  existingInstanceCount: IExistingInstanceCount;
  setExistingInstanceCount: any;
  setExistingModelRelations: any;
}

function ModelDeletionAlert({
  showAlert,
  setShowAlert,
  existingRelations,
  existingInstanceCount,
  setExistingInstanceCount,
  setExistingModelRelations,
}: IModelDeletionAlertProps) {
  const handleClose = () => {
    setExistingInstanceCount([]);
    setExistingModelRelations([]);
    setShowAlert(false);
  };

  return (
    <>
      <div className="main-wrapper">
        <Modal
          show={showAlert}
          onHide={handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              fontSize: "1.25rem",
              fontWeight: "500",
              margin: " 0px",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              lineHeight: " 1.6",
              letterSpacing: " 0.0075em",
              padding: "16px 24px",
              flex: "0 0 auto",
            }}
          >
            Alert
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center">
              <div>
                {existingRelations?.sourceModelRelations?.length > 0 ||
                existingRelations?.targetModelRelations?.length > 0 ? (
                  <p>
                    Please remove the following relationships to delete model{" "}
                    {existingRelations?.modelName}
                  </p>
                ) : null}
                {existingRelations?.sourceModelRelations?.length > 0 && (
                  <>
                    {existingRelations?.sourceModelRelations.map(
                      (relation: IModelRelationDetails) => (
                        <p key={relation.relationName}>
                          {relation.modelName} &gt; {relation.relationName} &gt;{" "}
                          {existingRelations?.modelName}
                        </p>
                      )
                    )}
                  </>
                )}
                {existingRelations?.targetModelRelations?.length > 0 && (
                  <>
                    {existingRelations?.targetModelRelations.map(
                      (relation: IModelRelationDetails) => (
                        <p key={relation.relationName}>
                          {relation.modelName} &gt; {relation.relationName} &gt;{" "}
                          {existingRelations?.modelName}
                        </p>
                      )
                    )}
                  </>
                )}
                {existingInstanceCount?.count > 0 && (
                  <>
                    <p key={existingInstanceCount?.modelName}>
                      Please delete {existingInstanceCount?.count} instances
                      mapped with model {existingInstanceCount?.modelName} to
                      continue
                    </p>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="seqrops-btn btn-outline" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default ModelDeletionAlert;
