import { IModelTreeEvent } from "components/model-tree.component/model-tree.types";
import { useState } from "react";
import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { setTargetId } from "store/asset-model/asset-model.slice";
import dropHereIcon from "../../assets/images/darg_models_icon.svg";
import ModelingTree from "../../components/model-tree.component/model-tree";
import "./tree-console.scss";

function TreeConsole(props: {
  modelId: number | undefined;
  updateModel: ({ id, name, isRootNode }: IModelTreeEvent) => void;
  deleteModel: ({ id, name, isRootNode }: IModelTreeEvent) => void;
  deleteRelation: (id: number, name: string) => void;
  addRelation: ({
    sourceModelId,
    targetModelId,
  }: {
    sourceModelId: number;
    targetModelId: number;
  }) => void;
}) {
  //DND
  const [, drop] = useDrop(() => ({
    accept: "model",
    drop: (item: { sourceModelId: number; targetModelId: number }) =>
      addModel(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const dispatch = useDispatch();
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const history = useNavigate();
  const isDirty = useAppSelector((state) => state.seqrOps.dirtyState.isDirty);
  const addModel = (relationDetails: {
    sourceModelId: number;
    targetModelId: number;
  }) => {
    const queryParams = "relation-create";
    history(`/?${queryParams}`);
    props.addRelation({
      sourceModelId: relationDetails.sourceModelId,
      targetModelId: relationDetails.targetModelId,
    });
    console.log(props);

    dispatch(setTargetId(relationDetails.targetModelId));
  };
  const [isBackGroundImg, setIsBackGroundImg] = useState(false);

  return (
    <>
      <div
        className="seqrops-tree-console-wrapper"
        id={theme ? "dark" : "light"}
        ref={drop}
        style={{
          backgroundImage: isBackGroundImg ? undefined : `url(${dropHereIcon})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container-fluid">
          <div className="seqrops-tree-model-title-section">
            <h4>Model Tree</h4>
          </div>
          <div className="seqrops-tree-model-section">
            <div className="d-tree">
              <ModelingTree
                modelId={props.modelId}
                updateModel={props.updateModel}
                deleteModel={props.deleteModel}
                deleteRelation={props.deleteRelation}
                setIsBackGroundImg={setIsBackGroundImg}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TreeConsole;
