import downloadIcon from "assets/images/file_download.svg";
import deleteIcon from "assets/images/seqrops_delete_icon.svg";
import DeleteConfirmationDialog from "components/dialogs/delete-confirmation.dialog";
import { FieldErrors } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { IStaticPropertyDetails } from "store/model-properties/model-property.interface";
import { setIsDirty } from "store/seqr-ops/seqr-ops.slice";
import { staticPropertyValidation } from "../static-property-validation";

function DefaultValueComponent({
  getValues,
  register,
  setError,
  errorWrap,
  errors,
  setValue,
  clearErrors,
  setFilePreview,
  filePreview,
  staticPropertyDetails,
}: {
  getValues: any;
  register: any;
  setError: any;
  setValue: any;
  clearErrors: any;
  errorWrap: (code: string | undefined) => void;
  errors: FieldErrors<IStaticPropertyDetails>;
  staticPropertyDetails: IStaticPropertyDetails | undefined;
  setFilePreview: (data: {
    url: string;
    referece: string;
    deleted: boolean;
  }) => void;
  filePreview: {
    url: string;
    referece: string;
    deleted: boolean;
  };
}) {
  const selectedType = getValues("type");
  const confirmationPopup = DeleteConfirmationDialog();
  const dispatch = useDispatch();
  const isDirty = useAppSelector((state) => state.seqrOps.dirtyState.isDirty);

  switch (selectedType) {
    case staticPropertyValidation.Types.BOOLEAN.value:
      return (
        <>
          <label htmlFor="defaultValue">Default Value</label>
          <select
            className="form-select"
            {...register("defaultValue")}
            disabled={staticPropertyDetails?.isReserved}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setIsDirty(true));
            }}
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
          <>{errorWrap(errors?.defaultValue?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.STRING.value:
      return (
        <>
          <label htmlFor="defaultValue">Default Value</label>
          <input
            className="form-control"
            type="text"
            minLength={0}
            maxLength={255}
            id="defaultValue"
            {...register("defaultValue", {})}
            disabled={staticPropertyDetails?.isReserved}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setIsDirty(true));
            }}
          />
          <>{errorWrap(errors?.defaultValue?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.INTEGER.value:
    case staticPropertyValidation.Types.SHORT.value:
    case staticPropertyValidation.Types.LONG.value:
      return (
        <>
          <label htmlFor="defaultValue">Default Value</label>
          <input
            type="number"
            className="form-control"
            id="defaultValue"
            pattern="^-\d+$"
            // value={defaultValue}
            // value={isNaN(defaultValue)? "" : defaultValue}
            {...register("defaultValue", {
              valueAsNumber: true,
              pattern: {
                value: /^-\d+$/,
                // value:/^-\d+$/,
                message: "Please enter a valid default value",
              },
            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("defaultValue");
              setValue("defaultValue", value);
              dispatch(setIsDirty(true));
            }}
          />
          <>{errorWrap(errors?.defaultValue?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.FLOAT.value:
    case staticPropertyValidation.Types.DOUBLE.value:
      return (
        <>
          <label htmlFor="defaultValue">Default Value</label>
          <input
            type="number"
            className="form-control"
            id="defaultValue"
            pattern="^-?\d*\.{0,1}\d+$"
            step="any"
            // value={ defaultValue}
            {...register("defaultValue", {
              valueAsNumber: true,
              pattern: {
                value: /^-?\d*\.{0,1}\d+$/,
                // value:/^\d+(\.\d+)$/,
                message: "Please enter a valid default value",
              },
            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("defaultValue");
              setValue("defaultValue", value);
              dispatch(setIsDirty(true));
            }}
          />
          <>{errorWrap(errors?.defaultValue?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.IMAGE.value:
    case staticPropertyValidation.Types.MIMICS.value:
    case staticPropertyValidation.Types.ICON.value:
    case staticPropertyValidation.Types.DOCUMENT.value:
      if (!filePreview.deleted && filePreview.url) {
        return (
          <>
            <span>
              <a
                href={filePreview.url}
                title={"Download"}
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={downloadIcon}
                  alt="seqrops edit button"
                  className="img-fluid seqrops-edit-button"
                />
              </a>
            </span>

            <span
              onClick={() => {
                confirmationPopup.onOpenDialog({
                  title: "seqrops-btn ",
                  message: "Are you sure want to delete the file?",
                  cancelBtn: "No",
                  successBtn: "Yes",
                  handleClose: () => {
                    dispatch(setIsDirty(true));
                    setFilePreview({
                      ...filePreview,
                      deleted: true,
                    });
                    confirmationPopup.closeDialog();
                  },
                });
              }}
            >
              <img
                src={deleteIcon}
                alt="seqrops edit button"
                className="img-fluid seqrops-edit-button"
              />
            </span>
          </>
        );
      }
      return (
        <>
          <label htmlFor="defaultFile">Default File</label>
          <input
            className="form-control"
            type="file"
            id="defaultFile"
            accept={staticPropertyValidation.filetypes[selectedType].join(", ")}
            {...register("defaultFile", {})}
            onChange={(e) => {
              dispatch(setIsDirty(true));
            }}
          />
          <>{errorWrap(errors?.defaultFile?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.DATETIME.value:
      return (
        <>
          <label htmlFor="defaultValue">Default Value</label>
          <select
            className="form-select"
            {...register("defaultValue")}
            disabled={staticPropertyDetails?.isReserved}
          >
            <option value="">Select</option>
            <option value="TODAY">Today</option>
          </select>
          <>{errorWrap(errors?.defaultValue?.message)}</>
        </>
      );
    default:
      return null;
  }
}

export default DefaultValueComponent;
