import { default as request } from "axios";
import {
  ASSET_MODEL_API,
  CLICKPOSITION,
  MESSAGES,
} from "config/default.config";
import { AppThunk } from "store";
import { toasterUtils } from "utils/toaster.utils";
import {
  IAddDynamicPropertyReq,
  IDynamicPropertyDetails,
  IStaticPropertyDetails,
} from "./model-property.interface";
import { modelPropertyService } from "./model-property.service";
import {
  setDynamicProperties,
  setSelectedTreeModelDynamicProps,
  setUnits,
} from "./model-property.slice";

// const addStaticProperty = (
//   isFileProperty: boolean,
//   fileData: FormData | undefined,
//   details: IAddStaticPropertyReq,
//   setError: any,
//   setSuccess: any
// ): AppThunk => {
//   return async (dispatch, getState) => {
//     try {
//       const response = await modelPropertyService.addStaticProperty(details);
//       if (response.success) {
//         if (isFileProperty && fileData) {
//           const propertyId = response?.data?.id;
//           const reference = response?.data?.reference;
//           fileData.append("reference", reference);
//           const fileSaveResponse = await modelPropertyService.addFileProperty(
//             fileData
//           );
//           if (fileSaveResponse.success) {
//             const url = fileSaveResponse?.data?.url;
//             details.id = propertyId;
//             details.defaultFile = url;

//             const propertyUpdateUrlResponse =
//               await modelPropertyService.updateStaticProperty(
//                 propertyId,
//                 details
//               );

//             if (propertyUpdateUrlResponse.success) {
//               toasterUtils.showSuccess(MESSAGES.STATIC_PROPERY_CREATE_SUCCESS);
//               setSuccess(true);
//               dispatch(getModelStaticProperties(response?.data?.modelId));
//             }
//           }
//         } else {
//           toasterUtils.showSuccess(MESSAGES.STATIC_PROPERY_CREATE_SUCCESS);
//           setSuccess(true);
//           dispatch(getModelStaticProperties(response?.data?.modelId));
//         }
//       }
//     } catch (error) {
//       if (request.isAxiosError(error) && error.response) {
//         showError(error.response?.data?.errorCode, setError);
//       }
//     }
//   };
// };

const showError = (errorCode: string, setError: any) => {
  let errorMessage: string = "";
  let fieldName: string = "";
  switch (errorCode) {
    case "NAME_FIELD_REQUIRED":
      fieldName = "name";
      errorMessage = "Please enter name";
      break;
    case "PROPERTY_NAME_EXISTS":
      fieldName = "name";
      errorMessage = MESSAGES.PROPERTY_NAME_EXISTS;
      break;
    default:
      fieldName = "";
      break;
  }

  if (fieldName) {
    setError(fieldName, {
      type: "custom",
      message: errorMessage,
    });
  } else {
    toasterUtils.showError(errorCode);
  }
};

// const updateStaticProperty = (
//   isFileProperty: boolean,
//   fileData: FormData | undefined,
//   details: IAddStaticPropertyReq,
//   setError: any,
//   setSuccess: any
// ): AppThunk => {
//   return async (dispatch, getState) => {
//     try {
//       if (isFileProperty && fileData) {
//         const propertyId = details.id;
//         const reference = details.reference;
//         fileData.append("reference", reference);
//         const fileSaveResponse = await modelPropertyService.updateFileProperty(
//           fileData
//         );
//         if (fileSaveResponse.success) {
//           const url = fileSaveResponse?.data?.url;
//           details.id = propertyId;
//           details.defaultFile = url;

//           const propertyUpdateUrlResponse =
//             await modelPropertyService.updateStaticProperty(
//               propertyId,
//               details
//             );

//           if (propertyUpdateUrlResponse.success) {
//             toasterUtils.showSuccess(MESSAGES.STATIC_PROPERY_UPDATE_SUCCESS);
//             setSuccess(true);
//             dispatch(getModelStaticProperties(details.id));
//           }
//         } else {
//           if (fileSaveResponse.errorCode === "FILE_NOT_FOUND") {
//             const fileSaveResponse = await modelPropertyService.addFileProperty(
//               fileData
//             );
//             if (fileSaveResponse.success) {
//               const url = fileSaveResponse?.data?.url;
//               details.id = propertyId;
//               details.defaultFile = url;

//               const propertyUpdateUrlResponse =
//                 await modelPropertyService.updateStaticProperty(
//                   propertyId,
//                   details
//                 );

//               if (propertyUpdateUrlResponse.success) {
//                 toasterUtils.showSuccess(
//                   MESSAGES.STATIC_PROPERY_UPDATE_SUCCESS
//                 );
//                 setSuccess(true);
//                 dispatch(getModelStaticProperties(details.id));
//               }
//             }
//           }
//         }
//       } else {
//         const response = await modelPropertyService.updateStaticProperty(
//           details.id,
//           details
//         );
//         if (response.success) {
//           toasterUtils.showSuccess(MESSAGES.STATIC_PROPERY_UPDATE_SUCCESS);
//           setSuccess(true);
//           dispatch(getModelStaticProperties(response?.data?.modelId));
//         }
//       }
//     } catch (error) {
//       if (request.isAxiosError(error) && error.response) {
//         showError(error.response?.data?.errorCode, setError);
//       }
//     }
//     return undefined;
//   };
// };

const downloadStaticProperty = async (reference: string): Promise<boolean> => {
  try {
    window.open(ASSET_MODEL_API.DOWNLOAD_STATIC_PROPERTY + reference);
    toasterUtils.showSuccess(MESSAGES.DOWNLOAD_SUCCESS);
    return true;
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
    }
  }
  return false;
};

const getStaticPropertyDetailsById = async (
  propertyId: number
): Promise<IStaticPropertyDetails | undefined> => {
  try {
    const response = await modelPropertyService.fetchStaticPropertyDetailsById(
      propertyId
    );
    return response.data || undefined;
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
    }
  }
  return undefined;
};

const getModelDynamicProperty = (
  modelId: number | undefined,
  clickPosition: string
): AppThunk => {
  return async (dispatch, getState) => {
    const response = await modelPropertyService.fetchAllDynamicProperty(
      modelId
    );
    if (response?.success) {
      if (
        clickPosition === CLICKPOSITION.SIDEBAR ||
        clickPosition === CLICKPOSITION.NONE
      ) {
        dispatch(setDynamicProperties(response?.data));
      } else if (clickPosition === CLICKPOSITION.TREE) {
        dispatch(setSelectedTreeModelDynamicProps(response?.data));
      }
    }
  };
};

const addDynamicProperty = (
  details: IAddDynamicPropertyReq,
  setError: any,
  setSuccess: any,
  setLoading: any,
  setIsDisableButton: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await modelPropertyService.addDynamicProperty(details);
      if (response.data.success) {
        toasterUtils.showSuccess(MESSAGES.DYNAMIC_PROPERTY_CREATE_SUCCESS);
        setSuccess(true);
        dispatch(
          getModelDynamicProperty(
            response?.data?.data?.modelId,
            CLICKPOSITION.NONE
          )
        );
      } else if (!response.data.success) {
        dispatch(setLoading(false));
        setIsDisableButton(false);
        const errorCode = response?.data?.errorCode;
        showError(errorCode, setError);
      }
    } catch (error) {
      console.log(request);
      setIsDisableButton(false);

      if (request.isAxiosError(error)) {
        const errorCode = error.response?.data?.errorCode;
        // showError(error.response?.data?.errorCode, setError);
        if (errorCode === "FORMULA_NOT_VALID") {
          dispatch(setLoading(false));
          setIsDisableButton(false);
          setError("computedFormula", {
            type: "custom",
            message: MESSAGES.FORMULA_NOT_VALID,
          });
        } else {
          setIsDisableButton(false);
          toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
        }
      }
    }
  };
};

const updateDynamicProperty = (
  id: any,
  details: IAddDynamicPropertyReq,
  setError: any,
  setSuccess: any,
  setLoading: any,
  setIsDisableButton: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await modelPropertyService.updateDynamicProperty(
        details
      );
      if (response.data.success) {
        toasterUtils.showSuccess(MESSAGES.DYNAMIC_PROPERTY_UPDATE_SUCCESS);
        setSuccess(true);
        dispatch(
          getModelDynamicProperty(
            response.data?.data?.modelId,
            CLICKPOSITION.NONE
          )
        );
        return;
      } else if (!response.data.success) {
        const errorCode = response?.data?.errorCode;
        if (errorCode === "FORMULA_NOT_VALID") {
          setError("computedFormula", {
            type: "custom",
            message: MESSAGES.FORMULA_NOT_VALID,
          });
          dispatch(setLoading(false));
          setIsDisableButton(false);
        } else if (errorCode === "PROPERTY_NAME_CANNOT_BE_EDITED") {
          setError("name", {
            type: "custom",
            message: MESSAGES.PROPERTY_NAME_CANNOT_BE_EDITED,
          });
          dispatch(setLoading(false));
          setIsDisableButton(false);
        } else {
          setIsDisableButton(false);
          showError(errorCode, setError);
        }
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const errorCode = error.response?.data?.errorCode;
        if (errorCode === "FORMULA_NOT_VALID") {
          setError("computedFormula", {
            type: "custom",
            message: MESSAGES.FORMULA_NOT_VALID,
          });
          dispatch(setLoading(false));
          setIsDisableButton(false);
        } else if (errorCode === "PROPERTY_NAME_CANNOT_BE_EDITED") {
          setError("name", {
            type: "custom",
            message: MESSAGES.PROPERTY_NAME_CANNOT_BE_EDITED,
          });
          dispatch(setLoading(false));
          setIsDisableButton(false);
        } else {
          setIsDisableButton(false);

          toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
        }
      }
    }
  };
};

const getModelDynamicPropertyById = async (
  id: number | undefined
): Promise<IDynamicPropertyDetails | undefined> => {
  try {
    const response = await modelPropertyService.getDynamicPropertyById(id);
    return response.data || undefined;
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
    }
  }
  return undefined;
};

// const deleteDynamicProperty = (
//   id: number,
//   setSuccess: (state: boolean) => void
// ): AppThunk => {
//   return async (dispatch, getState) => {
//     var modelId: number | undefined;
//     try {
//       const response = await modelPropertyService.deleteDynamicProperty(id);

//       if (response.success) {
//         const dynamicResponse =
//           await modelPropertyService.getDynamicPropertyById(id);
//         modelId = dynamicResponse?.data?.modelId;
//         toasterUtils.showSuccess(MESSAGES.DYNAMIC_PROPERTY_DELETE_SUCCESS);
//         setSuccess(true);
//         dispatch(getModelDynamicProperty(modelId));
//       }
//     } catch (error) {
//       if (request.isAxiosError(error) && error.response) {
//         toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
//       }
//     }
//   };
// };

const deleteDynamicProperty = async (id: number): Promise<boolean> => {
  try {
    const response = await modelPropertyService.deleteDynamicProperty(id);
    console.log(response);

    if (response.data.success) {
      toasterUtils.showSuccess(MESSAGES.DYNAMIC_PROPERTY_DELETE_SUCCESS);
    } else {
      const errorCode = response?.data?.errorCode;
      toasterUtils.showError(MESSAGES.PROPERTY_CANNOT_BE_DELETED);
    }
    // return true;
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const errorCode = error.response?.data?.errorCode;
      if (errorCode === "PROPERTY_CANNOT_BE_DELETED") {
        toasterUtils.showError(MESSAGES.PROPERTY_CANNOT_BE_DELETED);
      } else {
        toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
      }
    }
  }
  return false;
};

const getAllUnit = (): AppThunk => {
  return async (dispatch, getState) => {
    const response: any = await modelPropertyService.fetchAllUnit();
    if (response.success) {
      console.log("unitData", response.data);
      dispatch(setUnits(response?.data));
    }
  };
};

// const getAllUnit = async (): Promise<IDynamicPropertyUnits | undefined> => {
//   try {
//     const response = await modelPropertyService.fetchAllUnit();
//     console.log("unitData", response.data);

//     return response.data;
//   } catch (error) {
//     if (request.isAxiosError(error) && error.response) {
//       toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
//     }
//   }
//   return undefined;
// };

export const modelPropertyActions = {
  // getModelStaticProperties,
  // addStaticProperty,
  // updateStaticProperty,
  // deleteStaticProperty,
  getStaticPropertyDetailsById,
  addDynamicProperty,
  getModelDynamicProperty,
  updateDynamicProperty,
  downloadStaticProperty,
  getModelDynamicPropertyById,
  deleteDynamicProperty,
  showError,
  getAllUnit,
};
