import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import {
  setDynamicSwitch,
  setHasChange,
  setIsModelChange,
  setIsPublish,
  setStaticSwitch,
} from "store/seqr-ops/seqr-ops.slice";
import { useDialog } from "./DialogProvider";

function ConfirmationDialog() {
  const [openDialog, closeDialog] = useDialog();
  const dispatch = useDispatch();
  const staticSwitch = useAppSelector((state) => state.seqrOps.staticSwitch);
  const dynamicSwitch = useAppSelector((state) => state.seqrOps.dynamicSwitch);
  const history = useNavigate();
  const onOpenDialog = ({ handleClose }: { handleClose: () => void }) => {
    openDialog({
      children: (
        <>
          <DialogTitle>Unsaved Changes</DialogTitle>
          <DialogContent>
            You have unsaved changes. Are you sure you want to continue without
            saving these changes?
          </DialogContent>
          <DialogActions>
            <Button
              className="cancel-unsaved-changes seqrops-btn btn-outline"
              color="primary"
              onClick={() => {
                history(`/`);
                if (staticSwitch === true) {
                  dispatch(setStaticSwitch(false));
                }

                if (dynamicSwitch === true) {
                  dispatch(setDynamicSwitch(false));
                }
                dispatch(setHasChange(false));
                dispatch(setIsPublish(false));
                dispatch(setIsModelChange(0));
                closeDialog();
              }}
              style={{ marginBottom: "10px" }}
            >
              Cancel
            </Button>
            <Button
              className="seqrops-btn btn-fill"
              color="primary"
              onClick={() => {
                handleClose();
              }}
              style={{ marginBottom: "10px", marginRight: "18px" }}
            >
              OK
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  return {
    onOpenDialog,
    closeDialog,
  };
}

export default ConfirmationDialog;
