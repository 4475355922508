import axios from "axios";
import { ASSET_MODEL_API } from "config/default.config";
import { commonUtils } from "utils/common.utils";

const uploadFile = async (request: FormData) => {
  
  const response = await axios.post(
    ASSET_MODEL_API.UPLOAD_FILE_PROPERTY,
    request
  );
  return response.data;
};

const replaceFile = async (request: FormData) => {
  const response = await axios.post(
    ASSET_MODEL_API.REPLACE_FILE_PROPERTY,
    request
  );
  return response.data;
};

const deleteFile = async (reference: string) => {
  const response = await axios.delete(
    commonUtils.formatString(ASSET_MODEL_API.DELETE_FILE_PROPERTY, reference)
  );
  return response.data;
};

export const fileManagemntService = {
  uploadFile,
  replaceFile,
  deleteFile,
};
