import * as CryptoJS from "crypto-js";


const KEY = CryptoJS.enc.Hex.parse("253D3FB468A0E24677C28A624BE0F939");
const IV = CryptoJS.enc.Hex.parse("00000000000000000000000000000000");

const AUTH_KEY = CryptoJS.enc.Utf8.parse("oZYBtVEudbRkzCyx");
const AUTH_IV = CryptoJS.enc.Utf8.parse("riMlUOnqDJrEnCht");

const encryptURL = (content: any) => {
    try {
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(content), KEY, {
            mode: CryptoJS.mode.CBC,
            iv: IV,
            padding: CryptoJS.pad.Pkcs7,
        }).toString();
        return encodeURIComponent(ciphertext.toString());
    } catch (error) {
        return "";
    }
};

const decryptURL = (content: any) => {
    if (!content) {
        return null;
    }
    try {
        var decrypted = CryptoJS.AES.decrypt(
            decodeURIComponent(content.toString()),
            KEY,
            { mode: CryptoJS.mode.CBC, iv: IV, padding: CryptoJS.pad.Pkcs7 }
        );
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        return null;
    }
};

const encryptAuthKey = (content: any) => {

    const KEY2 = CryptoJS.enc.Utf8.parse("oZYBtVEudbRkzCyx");
    const IV2 = CryptoJS.enc.Utf8.parse("riMlUOnqDJrEnCht");
    
      try {
        var ciphertext = CryptoJS.AES.encrypt(content, KEY2, {
          mode: CryptoJS.mode.CBC,
          iv: IV2,
          padding: CryptoJS.pad.Pkcs7,
        }).toString();
        return ciphertext;
      } catch (error) {
        return "";
      }
    };
    
export const encryptUtils = {
    encryptURL,
    decryptURL,
    encryptAuthKey,
};