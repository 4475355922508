import axios, { default as request } from "axios";
import {
  ASSET_MODEL_API,
  CLICKPOSITION,
  MESSAGES,
} from "config/default.config";
import { IExistingInstanceCount } from "modules/asset-model-crud/delete-model";
import { AppThunk } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import { assetModelService } from "store/asset-model/asset-model.service";
import { fileManagemntActions } from "store/file-management/file-management.action";
import { toasterUtils } from "utils/toaster.utils";
import {
  IAddStaticPropertyReq,
  IPropertyTypeChangeValidationReq,
} from "./model-property.interface";
import {
  setSelectedTreeModelStaticProps,
  setStaticProperties,
} from "./model-property.slice";
import { staticPropertyService } from "./static-property.service";

const getModelStaticProperties = (
  modelId: number,
  clickPosition: string
): AppThunk => {
  return async (dispatch, getState) => {
    const response = await staticPropertyService.fetchAllStaticProperties(
      modelId
    );
    if (response.success) {
      if (
        clickPosition === CLICKPOSITION.SIDEBAR ||
        clickPosition === CLICKPOSITION.NONE
      ) {
        dispatch(setStaticProperties(response?.data));
      } else if (clickPosition === CLICKPOSITION.TREE) {
        dispatch(setSelectedTreeModelStaticProps(response?.data));
      }
    }
  };
};

const propertySuccessAction = (
  message: string,
  setSuccess: (state: boolean) => void,
  modelId: number
): AppThunk => {
  return async (dispatch, getState) => {
    toasterUtils.showSuccess(message);
    setSuccess(true);
    dispatch(getModelStaticProperties(modelId, CLICKPOSITION.NONE));
  };
};

const createStaticProperty = (
  isFileProperty: boolean,
  fileData: FormData | undefined,
  details: IAddStaticPropertyReq,
  setError: any,
  setSuccess: any,
  setIsDisableButton: any,
  setLoading: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      // if (staticPropertyData.success) {
      if (isFileProperty && fileData) {
        // With File
        axios.get(ASSET_MODEL_API.REFERENCE_URL).then(async (response) => {
          fileData?.append("reference", response.data.data || "");
          details.reference = response.data.data;
          const fileDataResponse = await fileManagemntActions.uploadFile(
            fileData
          );

          if (fileDataResponse) {
            const url = fileDataResponse.url || "";
            // details.id = staticPropertyData.data.id;

            details.defaultFile = url;

            const staticPropertyData =
              await staticPropertyService.addStaticProperty(details);

            if (staticPropertyData.data.success) {
              dispatch(
                propertySuccessAction(
                  MESSAGES.STATIC_PROPERY_CREATE_SUCCESS,
                  setSuccess,
                  staticPropertyData.data.data.modelId
                )
              );
            } else if (!staticPropertyData.data.success) {
              setIsDisableButton(false);
              dispatch(setLoading(false));
              showError(staticPropertyData?.data?.errorCode, setError);
            }
          }
        });
      } else {
        // Without File
        const staticPropertyData =
          await staticPropertyService.addStaticProperty(details);
        if (staticPropertyData.data.success) {
          dispatch(
            propertySuccessAction(
              MESSAGES.STATIC_PROPERY_CREATE_SUCCESS,
              setSuccess,
              staticPropertyData.data.data.modelId
            )
          );
        } else if (!staticPropertyData.data.success) {
          dispatch(setLoading(false));
          setIsDisableButton(false);
          if (
            staticPropertyData.data.errorCode ===
            MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS
          ) {
            toasterUtils.showError(
              MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS_MESSAGE
            );
          } else {
            showError(staticPropertyData?.data?.errorCode, setError);
          }
        }
      }
      // }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        showError(error.response?.data?.errorCode, setError);
      }
    }
  };
};

const updateStaticProperty = (
  details: IAddStaticPropertyReq,
  setError: any,
  setSuccess: any,
  setIsDisableButton: any,
  setLoading: any,
  setExistingInstanceCount: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const modelResponse = await assetModelActions.getModelById(
        details.modelId
      );

      if (modelResponse.success) {
        let modelName = modelResponse?.data?.name;

        let propertyTypeChangeValidationReq: IPropertyTypeChangeValidationReq =
          {
            propertyName: details.name,
            propertyType: details.type,
            vertexName: modelName,
          };
        const propertyTypeChangeValidationResponse =
          await staticPropertyService.propertyTypeChangeValidation(
            propertyTypeChangeValidationReq
          );

        if (propertyTypeChangeValidationResponse?.status) {
          const responseFromManagement =
            await assetModelService.checkIfModelInstanceExist(modelName);
          if (responseFromManagement.success) {
            const propertyUpdateUrlResponse =
              await staticPropertyService.updateStaticProperty(details);
            if (propertyUpdateUrlResponse.data.success) {
              dispatch(
                propertySuccessAction(
                  MESSAGES.STATIC_PROPERY_UPDATE_SUCCESS,
                  setSuccess,
                  details.modelId
                )
              );
            } else if (!propertyUpdateUrlResponse.data.success) {
              setIsDisableButton(false);
              dispatch(setLoading(false));
              if (
                propertyUpdateUrlResponse.data.errorCode ===
                MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS
              ) {
                toasterUtils.showError(
                  MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS_MESSAGE
                );
              } else {
                const errorCode = propertyUpdateUrlResponse?.data?.errorCode;
                showError(errorCode, setError);
              }
            }
          } else if (!responseFromManagement?.success) {
            setIsDisableButton(false);
            dispatch(setLoading(false));
            let existingInstanceCount: IExistingInstanceCount = {
              modelName: modelName,
              count: responseFromManagement?.data,
            };
            setExistingInstanceCount(existingInstanceCount);
          }
        } else if (!propertyTypeChangeValidationResponse?.status) {
          setIsDisableButton(false);
          dispatch(setLoading(false));
          const errorCode = modelResponse?.data?.errorCode;
          showError(modelResponse?.data?.errorCode, setError);
        }
      } else if (!modelResponse.data.success) {
        setIsDisableButton(false);
        dispatch(setLoading(false));
        const errorCode = modelResponse?.data?.errorCode;
        showError(modelResponse?.data?.errorCode, setError);
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        dispatch(setLoading(false));
        setIsDisableButton(false);
        showError(error.response?.data?.errorCode, setError);
      }
    }
    return undefined;
  };
};

const createFileAndUpdateStaticProperty = (
  fileData: FormData,
  details: IAddStaticPropertyReq,
  setError: any,
  setSuccess: any,
  setIsDisableButton: any,
  setLoading: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      // dispatch(setLoading(true));
      await axios.get(ASSET_MODEL_API.REFERENCE_URL).then(async (response) => {
        fileData?.append("reference", response.data.data || "");
        const fileDataResponse = await fileManagemntActions.uploadFile(
          fileData
        );
        const url = fileDataResponse.url || "";
        details.defaultFile = url;
      });

      const propertyUpdateUrlResponse =
        await staticPropertyService.updateStaticProperty(details);

      if (propertyUpdateUrlResponse.data.success) {
        dispatch(
          propertySuccessAction(
            MESSAGES.STATIC_PROPERY_CREATE_SUCCESS,
            setSuccess,
            details.modelId
          )
        );
        // dispatch(setLoading(false));
      } else if (!propertyUpdateUrlResponse.data.success) {
        const errorCode = propertyUpdateUrlResponse?.data?.errorCode;
        if (
          propertyUpdateUrlResponse.data.errorCode ===
          MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS
        ) {
          toasterUtils.showError(
            MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS_MESSAGE
          );
        } else {
          const errorCode = propertyUpdateUrlResponse?.data?.errorCode;
          setError(errorCode, setError);
        }
        setIsDisableButton(false);
        dispatch(setLoading(false));
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        dispatch(setLoading(false));
        setIsDisableButton(false);
        showError(error.response?.data?.errorCode, setError);
      }
    }
    return undefined;
  };
};

const deleteFileAndUpdateStaticProperty = (
  details: IAddStaticPropertyReq,
  setError: any,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      await fileManagemntActions.deleteFile(details.reference);
      details.defaultFile = "";

      const propertyUpdateUrlResponse =
        await staticPropertyService.updateStaticProperty(details);

      if (propertyUpdateUrlResponse.data.success) {
        dispatch(
          propertySuccessAction(
            MESSAGES.STATIC_PROPERY_CREATE_SUCCESS,
            setSuccess,
            details.modelId
          )
        );
      } else if (!propertyUpdateUrlResponse.data.success) {
        if (
          propertyUpdateUrlResponse.data.errorCode ===
          MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS
        ) {
          toasterUtils.showError(
            MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS_MESSAGE
          );
        } else {
          const errorCode = propertyUpdateUrlResponse?.data?.errorCode;
          setError(errorCode, setError);
        }
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        showError(error.response?.data?.errorCode, setError);
      }
    }
    return undefined;
  };
};

const replaceFileAndUpdateStaticProperty = (
  fileData: FormData,
  details: IAddStaticPropertyReq,
  setError: any,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      console.log("details::::", fileData);

      await axios.get(ASSET_MODEL_API.REFERENCE_URL).then(async (response) => {
        fileData?.append("reference", response.data.data || "");
        const res = await fileManagemntActions.uploadFile(fileData);
        const fileDataResponse = await fileManagemntActions.replaceFile(
          fileData
        );
        console.log("after");

        console.log("fileDataResponse.url::", fileDataResponse);

        const url = fileDataResponse.url || "";
        details.defaultFile = url;
      });
      const propertyUpdateUrlResponse =
        await staticPropertyService.updateStaticProperty(details);

      if (propertyUpdateUrlResponse.data.success) {
        dispatch(
          propertySuccessAction(
            MESSAGES.STATIC_PROPERY_CREATE_SUCCESS,
            setSuccess,
            details.modelId
          )
        );
      } else if (!propertyUpdateUrlResponse.data.success) {
        if (
          propertyUpdateUrlResponse.data.errorCode ===
          MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS
        ) {
          toasterUtils.showError(
            MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS_MESSAGE
          );
        } else {
          const errorCode = propertyUpdateUrlResponse?.data?.errorCode;
          setError(errorCode, setError);
        }
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        showError(error.response?.data?.errorCode, setError);
      }
    }
  };
};

const showError = (errorCode: string, setError: any) => {
  let errorMessage: string = "";
  let fieldName: string = "";
  switch (errorCode) {
    case "NAME_FIELD_REQUIRED":
      fieldName = "name";
      errorMessage = "Please enter name";
      break;
    case "RESERVED_PROPERTY_NAME":
      fieldName = "name";
      errorMessage = MESSAGES.RESERVED_PROPERTY_NAME;
      break;
    case "PROPERTY_NAME_EXISTS":
      fieldName = "name";
      errorMessage = MESSAGES.PROPERTY_NAME_EXISTS;
      break;
    case "MIN_MAX_REQUIRED":
      // fieldName = "minLength";
      errorMessage = MESSAGES.MIN_MAX_REQUIRED;
      break;
    default:
      break;
  }

  if (errorCode == "MIN_MAX_REQUIRED") {
    setError("minLength", {
      type: "custom",
      message: "Min length is required",
    });
    setError("maxLength", {
      type: "custom",
      message: "Max length is required",
    });
  } else if (fieldName) {
    setError(fieldName, {
      type: "custom",
      message: errorMessage,
    });
  } else {
    toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
  }
};

const deleteStaticProperty = async (
  propertyId: number,
  reference: string,
  deleteFile: boolean
): Promise<boolean> => {
  try {
    if (deleteFile) {
      await staticPropertyService.deleteStaticProperty(propertyId);
      await fileManagemntActions.deleteFile(reference);
    } else {
      await staticPropertyService.deleteStaticProperty(propertyId);
    }
    toasterUtils.showSuccess(MESSAGES.STATIC_PROPERY_DELETE_SUCCESS);
    return true;
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
    }
  }
  return false;
};

export const staticPropertyActions = {
  createStaticProperty,
  updateStaticProperty,
  createFileAndUpdateStaticProperty,
  deleteFileAndUpdateStaticProperty,
  replaceFileAndUpdateStaticProperty,
  deleteStaticProperty,
  getModelStaticProperties,
};
