import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import { setLoading } from "store/seqr-ops/seqr-ops.slice";
import "./delete-relation.scss";

interface IDeleteRelationScreenProps {
  showModel: boolean;
  setShowModel: (show: boolean) => void;
  id: number;
  name: string;
  sourceId: number;
}

function DeleteRelationScreen({
  showModel,
  setShowModel,
  id,
  name,
  sourceId,
}: IDeleteRelationScreenProps) {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setShowModel(false);
  };
  const [isDisableButton, setIsDisableButton] = useState(false);

  const setSuccess = (state: boolean) => {
    setShowModel(false);
    setIsDisableButton(false);
    dispatch(setLoading(false));
  };

  const handleConfirm = () => {
    setIsDisableButton(true);
    dispatch(setLoading(true));
    dispatch(assetModelActions.deleteRelation(id, sourceId, setSuccess));
  };

  return (
    <>
      <div className="main-wrapper">
        <Modal
          show={showModel}
          onHide={handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              fontSize: "1.25rem",
              fontWeight: "500",
              margin: " 0px",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              lineHeight: " 1.6",
              letterSpacing: " 0.0075em",
              padding: "16px 24px",
              flex: "0 0 auto",
            }}
          >
            Delete Confirmation
          </Modal.Header>
          <Modal.Body className="delete_model">
            <div className="d-flex align-items-center">
              {/* <img className="warning" src={warning} alt="" />  */}
              <div>
                Are you sure you want to delete relation <b>{name}</b> ?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="seqrops-btn btn-outline me-2"
              onClick={handleClose}
            >
              No
            </Button>
            <Button className="seqrops-btn btn-fill" onClick={handleConfirm}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default DeleteRelationScreen;
