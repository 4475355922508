import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { IModelTreeActions } from "../model-tree.types";
import NodeToggle from "./node-toggle";

function NodeActionMenu({
  id,
  name,
  isRootNode,
  description,
  isEquipment,
  icon,
  isEdge,
  actions,
}: {
  id: number;
  name: string;
  isRootNode: boolean;
  description: string;
  isEquipment: boolean;
  icon: FileList | string;
  isEdge: boolean;
  actions: IModelTreeActions;
}) {
  const dispatch = useDispatch()
  const isDirty = useAppSelector((state) => state.seqrOps.dirtyState.isDirty)
  const history = useNavigate()
  const [editClicked, setEditClicked] = useState(false)
  const [deleteClicked, setDeleteClicked] = useState(false)
  const [relationDeleteClicked, setRelationDeleteClicked] = useState(false)

  const location = useLocation()
  useEffect(() => {
    if (isDirty === false && editClicked === true && location.search === "?create-model-edit/true") {
      handleEdit()
    } else if (isDirty === false && deleteClicked === true && location.search === "?model-delete/true") {
      handleDelete()
    } else if (isDirty === false && relationDeleteClicked === true && location.search === "?relation-delete/true") {
      handleDelete()
    }
  }, [isDirty, location])
  const handleEdit = () => {
    if (isDirty) {

    } else {
      setEditClicked(false)
      actions.updateModel({ id, name, isRootNode, description, isEquipment, icon });
    }
  }
  const handleDelete = () => {
    if (isDirty) {

    } else {
      setDeleteClicked(false)
      actions.deleteModel({ id, name, isRootNode, description, isEquipment, icon });
    }
  }
  const handleRelationDelete = () => {
    if (isDirty) {

    } else {
      setDeleteClicked(false)
      actions.deleteRelation(id, name);
    }
  }

  return (
    <>
      <div className="seqrops-node-option-section d-inline-flex">
        <Tooltip title="options">
          <>
            <Dropdown className="node-options" drop="end">
              <Dropdown.Toggle
                className="removecaret"
                id="dropdown-autoclose-true"
                as={NodeToggle}
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                {isEdge ? (
                  <Dropdown.Item
                    onClick={() => {
                      const queryParams = "relation-delete"
                      history(`/?${queryParams}/${isDirty}`);

                      setRelationDeleteClicked(true)
                      handleRelationDelete()
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                ) : (
                  <>
                    <Dropdown.Item
                      onClick={() => {
                        const queryParams = "create-model-edit"
                        history(`/?${queryParams}/${isDirty}`);
                        setEditClicked(true)
                        handleEdit()
                      }}>
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        const queryParams = "model-delete"
                        history(`/?${queryParams}/${isDirty}`);

                        setDeleteClicked(true)
                        handleDelete()
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </>
        </Tooltip>
      </div>
    </>
  );
}

export default NodeActionMenu;
