import closeBtn from "assets/images/seqrops_close_icon.svg";
import ConfirmationDialog from "components/dialogs/confirmation.dialog";
import CreateRelationshipScreen from "modules/model-relation-crud/create-relation";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import { ICreateModelRelationReq } from "store/asset-model/asset-model.interface";
import { setLoading, setRelationDirty } from "store/seqr-ops/seqr-ops.slice";
import "./model-relation-console.scss";

interface IRelationForm {
  relationshipName: string;
  min: number | undefined;
  max: number | undefined;
  targetId: number | undefined;
  isAssoc: boolean;
}
function ModelRelationConsole({
  showModel,
  setShowModel,
  details,
}: {
  showModel: boolean;
  setShowModel: (state: boolean) => void;
  details: {
    sourceModelId: number;
    targetModelId: number;
  };
}) {
  const selector = useAppSelector((state) => state.assetModels.targetId);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState,
    reset,
    clearErrors,
    setError,
    setValue,
  } = useForm<IRelationForm>({
    defaultValues: {
      relationshipName: "",
      min: undefined,
      max: undefined,
      targetId: undefined,
      isAssoc: false,
    },
  });
  const errors: any = formState.errors;
  const isDirty = useAppSelector((state) => state.seqrOps.isRelationDirty);
  const isDirtyForm = useAppSelector(
    (State) => State.seqrOps.dirtyState.isDirty
  );
  const history = useNavigate();
  const confirmationPopup = ConfirmationDialog();
  const resetAndClose = () => {
    history("/");
    confirmationPopup.closeDialog();
    setShowModel(false);
    reset();
    dispatch(setRelationDirty(false));
  };

  const handleClose = () => {
    if (isDirty) {
      confirmationPopup.onOpenDialog({
        handleClose() {
          resetAndClose();
          dispatch(setRelationDirty(false));
        },
      });
    } else {
      history("/");
      setShowModel(false);
      reset();
    }
  };
  const location = useLocation();
  useEffect(() => {
    setShowModel(false);
    // console.log(showModel);
    // if (location.search === "?create-model" || location.search === "?publish"
    //   || location.search === "?dynamic-property-table" || location.search === "?static-property-table"
    //   || location.search === "?dynamic-property-form" || location.search === "?static-propery-form"
    //   || location.search === "") {
    //   setShowModel(false)
    // } else {
    //   setShowModel(true)
    // }

    if (isDirtyForm === true && location.search === "?relation-create/true") {
      setShowModel(false);
    } else if (
      (isDirtyForm === false && location.search === "?relation-create") ||
      location.search === "?relation-create/false"
    ) {
      setShowModel(true);
    } else {
      setShowModel(false);
    }
  }, [location]);

  const setSuccess = () => {
    setShowModel(false);
    reset();
    dispatch(setRelationDirty(false));
    setIsDisableButton(false);
    dispatch(setLoading(false));
  };
  const selectedModelId = useAppSelector(
    (state) => state.assetModels.selectedModel?.id
  );
  let createRelation: any = false;
  if (selectedModelId === selector) {
    createRelation = true;
  }
  const [isDisableButton, setIsDisableButton] = useState(false);

  // //handling save
  const handleSave = (relation: IRelationForm) => {
    dispatch(setLoading(true));
    setIsDisableButton(true);
    relation.targetId = selector;
    if (selectedModelId !== selector) {
      if (!relation.targetId) {
        console.log("error");
        setError("targetId", {
          type: "custom",
          message: "Please select target model",
        });
        return;
      }
      if (relation.min && relation.max) {
        console.log(Number(relation.min));
        if (Number(relation.min) === 0 && Number(relation.max) === 0) {
          setError("min", {
            type: "custom",
            message: "Min and Max both cannot be zero",
          });
          return;
        }
        if (Number(relation.min) > Number(relation.max)) {
          setError("min", {
            type: "custom",
            message: "Min should be less than max",
          });
          return;
        }
      }

      const relationReq: ICreateModelRelationReq = {
        relationshipName: relation.relationshipName,
        min: relation.min || 1,
        max: relation.max || 1,
        targetId: relation.targetId,
        isAssoc: relation.isAssoc,
        associationId: relation.isAssoc ? relation.targetId : null,
        sourceId: details.sourceModelId,
      };
      dispatch(setRelationDirty(false));
      dispatch(
        assetModelActions.createRelation(
          relationReq,
          setError,
          setSuccess,
          setIsDisableButton,
          setLoading
        )
      );
      const queryParams = "relation-create";
      history(`/`);
    } else if (selectedModelId === selector) {
      dispatch(setLoading(false));
      dispatch(setRelationDirty(false));
      setIsDisableButton(false);
    }
  };
  const errorWrap = (errorMsg: string) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };
  return (
    <>
      <div className="main-wrapper">
        <Modal
          show={showModel}
          onHide={handleClose}
          backdrop="static"
          // keyboard={false}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title>Create Relationship</Modal.Title>
            <button
              className="seqrops-relationship-popup-close-btn"
              onClick={() => {
                handleClose();
              }}
            >
              <img src={closeBtn} alt="" />
            </button>
          </Modal.Header>
          <Modal.Body>
            {/* <label htmlFor="modelName">Relationship</label> */}
            {/* <div className="d-flex">
              <Autocomplete
                id="seqrops-relation-selecting-dropdown"
                disableClearable
                onChange={(e, data) => {
                  onChange(data.id, assetRelationProperties);
                }}
                options={assetRelations}
                defaultValue={modelRelations[0]}
                getOptionLabel={(option: any) => option.relationName}
                renderInput={(params) => {
                  return <TextField {...params} key={params.id} label="" />;
                }}
              />
              <div className="div">
                <button
                  className="seqrops-new-relationship-add-button"
                  onClick={() => {
                    setRelationAddingFormFlag(!relationAddingFormFlag);
                  }}
                >
                  <img src={seqropsAddBtn} alt="seqrops add Btn" />
                </button>
              </div>
            </div> */}
            <>
              {/* <div className="seqrops-view-existing-relation-details-section">
                <div className="seqrops-relation-details-title-section">
                  <p>Has a property list</p>
                </div>
                <div className="seqrops-relation-details-section">
                  <label htmlFor="Name">Name</label>
                  <label className="property-item m-1">
                    {assetRelationProperties.relationName}
                  </label>

                  <label htmlFor="targetType">Target type</label>
                  <label className="property-item m-1">
                    {assetRelationProperties.targetName}
                  </label>

                  <label htmlFor="Source count">Source Count</label>
                  <label className="property-item m-1">
                    {assetRelationProperties.srcCount}
                  </label>

                  <label htmlFor="Target count">Target Count</label>
                  <label className="property-item m-1">
                    {assetRelationProperties.targetCount}
                  </label>
                </div>
              </div> */}
            </>
            {/* ) : (
              <>
                <div className="seqrops-create-new-relationship-section">
                  <CreateRelationshipScreen
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    targetTypeSetTrigger={setDefaultTargetTypeId}
                    sourceModelId={sourceModelId}
                  />
                </div>
              </>
            )} */}
            <CreateRelationshipScreen
              selector={selector}
              register={register}
              setValue={setValue}
              errors={errors}
              clearErrors={clearErrors}
              createRelation={createRelation}
            />
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="cancel-create-relation seqrops-btn btn-outline me-2 seqrops-cancel-create-relation-btn"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="seqrops-btn btn-fill seqrops-create-relation-btn"
              onClick={handleSubmit(handleSave)}
              disabled={isDisableButton}
            >
              Create
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default ModelRelationConsole;
