import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SESSION_KEY } from "config/default.config";
import { sessionStorageUtils } from "utils/session-storage.utils";
import {
  IPublishDetails,
  ISeqrOpsNavigationState,
  ISeqrOpsState,
} from "./seqr-ops.interface";

const sessionState: any = sessionStorageUtils.getLocalStorage(
  SESSION_KEY.NAVIGATION_STATE
);

//initial state
const initialState: ISeqrOpsState = {
  navigationState: sessionState || {
    selectedModelId: 0,
    sidebarConsole: false,
    sidebarShowAllModels: false,
    propertyConsole: false,
    propertyConsoleStatic: false,
  },
  loginState: {
    isLoggedIn: false,
  },
  loadingState: {
    loading: false,
  },
  dirtyState: {
    isDirty: false,
  },
  hasChange: false,
  staticProperty: false,
  isPublish: false,
  isModelChange: 0,
  dynamicSwitch: false,
  staticSwitch: false,
  switchItem: "",
  isRelationDirty: false,
  publishDetails: {
    publishStatus: "",
    username: "",
  },
  theme: false,
};

const seqrOpsSlice = createSlice({
  name: "seqrOpsSlice",
  initialState: initialState,
  reducers: {
    setNavigationState: (
      state,
      action: PayloadAction<ISeqrOpsNavigationState>
    ) => {
      let session = sessionStorageUtils.getLocalStorage(
        SESSION_KEY.NAVIGATION_STATE
      );
      if (session) {
        state.navigationState = {
          ...session,
          ...state.navigationState,
          ...action.payload,
        };
      } else {
        state.navigationState = {
          ...state.navigationState,
          ...action.payload,
        };
      }

      sessionStorageUtils.setLocalStorage(
        SESSION_KEY.NAVIGATION_STATE,
        state.navigationState
      );
    },
    setLogin: (state, action: PayloadAction<boolean>) => {
      state.loginState = {
        ...state.loginState,
        isLoggedIn: action.payload,
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingState = {
        ...state.loadingState,
        loading: action.payload,
      };
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.dirtyState = {
        ...state.dirtyState,
        isDirty: action.payload,
      };
    },
    setHasChange: (state, action: PayloadAction<boolean>) => {
      state.hasChange = action.payload;
    },
    setStaticProperty: (state, action: PayloadAction<boolean>) => {
      state.staticProperty = action.payload;
    },
    setIsPublish: (state, action: PayloadAction<boolean>) => {
      state.isPublish = action.payload;
    },
    setIsModelChange: (state, action: PayloadAction<number>) => {
      state.isModelChange = action.payload;
    },
    setDynamicSwitch: (state, action: PayloadAction<boolean>) => {
      state.dynamicSwitch = action.payload;
    },
    setStaticSwitch: (state, action: PayloadAction<boolean>) => {
      state.staticSwitch = action.payload;
    },
    setSwitchItem: (state, action: PayloadAction<string>) => {
      state.switchItem = action.payload;
    },
    setRelationDirty: (state, action: PayloadAction<boolean>) => {
      state.isRelationDirty = action.payload;
    },
    setPublishDetails: (state, action: PayloadAction<IPublishDetails>) => {
      state.publishDetails = {
        ...state.publishDetails,
        publishStatus: action.payload?.publishStatus,
        username: action.payload?.username,
      };
    },
    setTheme: (state, action: PayloadAction<boolean>) => {
      state.theme = action.payload;
    },
  },
});

export const {
  setNavigationState,
  setLogin,
  setLoading,
  setIsDirty,
  setHasChange,
  setStaticProperty,
  setIsPublish,
  setIsModelChange,
  setDynamicSwitch,
  setStaticSwitch,
  setSwitchItem,
  setRelationDirty,
  setPublishDetails,
  setTheme,
} = seqrOpsSlice.actions;
export default seqrOpsSlice.reducer;
