import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import { setLoading } from "store/seqr-ops/seqr-ops.slice";
import "./delete-model.scss";
import ModelDeletionAlert from "./model-deletion-alert";
interface IDeleteModelScreenProps {
  showModel: boolean;
  setShowModel: (show: boolean) => void;
  modelDetails:
    | {
        id: number;
        name: string;
        isRootNode: boolean;
        description: string;
        icon: string | FileList;
      }
    | undefined;
}

export interface IModelRelationDetails {
  relationName: string;
  modelName: string;
}
export interface IExistinModelRelations {
  sourceModelRelations: any[];
  targetModelRelations: any[];
  modelName: String;
}

export interface IDeleteModelForm {
  id: number;
  name: string;
  isRootNode: boolean;
}

export interface IExistingInstanceCount {
  modelName: string;
  count: number;
}

function DeleteModelScreen({
  showModel,
  setShowModel,
  modelDetails,
}: IDeleteModelScreenProps) {
  const dispatch = useAppDispatch();
  const [isDisableButton, setIsDisableButton] = useState(false);
  const [existinModelRelations, setExistingModelRelations] =
    useState<IExistinModelRelations>({
      sourceModelRelations: [],
      targetModelRelations: [],
      modelName: "",
    });
  const [existingInstanceCount, setExistingInstanceCount] =
    useState<IExistingInstanceCount>({
      modelName: "",
      count: 0,
    });
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleClose = () => {
    setShowModel(false);
  };

  const setSuccess = (state: boolean) => {
    setShowModel(!state);
    setIsDisableButton(false);
    dispatch(setLoading(false));
  };

  const handleConfirm = () => {
    setIsDisableButton(true);
    dispatch(setLoading(true));
    dispatch(
      assetModelActions.deleteModel(
        modelDetails?.icon || "",
        modelDetails?.id || 0,
        setSuccess,
        setExistingModelRelations,
        setExistingInstanceCount
      )
    );
  };

  useEffect(() => {
    console.log(existinModelRelations);
    if (
      existinModelRelations?.sourceModelRelations?.length > 0 ||
      existinModelRelations?.targetModelRelations?.length > 0
    ) {
      handleClose();
      setShowAlert(true);
    }
  }, [existinModelRelations]);

  useEffect(() => {
    if (existingInstanceCount.count > 0) {
      handleClose();
      setShowAlert(true);
    }
  }, [existingInstanceCount]);

  return (
    <>
      <div className="main-wrapper">
        <Modal
          show={showModel}
          onHide={handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              fontSize: "1.25rem",
              fontWeight: "500",
              margin: " 0px",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              lineHeight: " 1.6",
              letterSpacing: " 0.0075em",
              padding: "16px 24px",
              flex: "0 0 auto",
            }}
          >
            Delete Confirmation
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center">
              {/* <img className="warning" src={warning} alt="" />  */}

              <div>
                Are you sure you want to delete model{" "}
                <b>{modelDetails?.name}</b> ?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="no-delete-model seqrops-btn btn-outline"
              onClick={handleClose}
            >
              No
            </Button>
            <Button className="seqrops-btn btn-fill" onClick={handleConfirm}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ModelDeletionAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        existingRelations={existinModelRelations}
        existingInstanceCount={existingInstanceCount}
        setExistingInstanceCount={setExistingInstanceCount}
        setExistingModelRelations={setExistingModelRelations}
      ></ModelDeletionAlert>
    </>
  );
}

export default DeleteModelScreen;
