import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useAppDispatch } from "store";
import { setIsPublish } from "store/seqr-ops/seqr-ops.slice";
import { useDialog } from "./DialogProvider";

function PublishConfirmationDialog() {
  const [openDialog, closeDialog] = useDialog();
  const dispatch = useAppDispatch();
  const onOpenDialog = ({
    title,
    message,
    cancelBtn,
    successBtn,
    handleClose,
  }: {
    title: string;
    message: string;
    cancelBtn: string;
    successBtn: string;
    handleClose: () => void;
  }) => {
    openDialog({
      children: (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{message}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                closeDialog();
                dispatch(setIsPublish(false));
              }}
            >
              {cancelBtn}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleClose();
              }}
            >
              {successBtn}
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  return {
    onOpenDialog,
    closeDialog,
  };
}

export default PublishConfirmationDialog;
