import collapsemenu from "assets/images/collapse_btn.svg";
import filterIcon from "assets/images/filter_icon.svg";
import filterChangedIcon from "assets/images/filter_outline_icon.svg";

import searchImage from "assets/images/seqrops_search_icon.svg";
import ModelListItem from "components/sidebar-list.component/sidebar-list.component";
import { OverlayPanel } from "primereact/overlaypanel";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import { seqrOpsActions } from "store/seqr-ops/seqr-ops.action";
import "./sidebar-console.scss";
const SideBar = () => {
  const dispatch = useAppDispatch();
  const models = useAppSelector((state) => state.assetModels.models);
  const selectedModels = useAppSelector(
    (state) => state.assetModels.selectedModels
  );
  const selectedModel = useAppSelector(
    (state) => state.assetModels.selectedModel
  );
  const navigationState = useAppSelector(
    (state) => state.seqrOps.navigationState
  );
  const isDirty = useAppSelector((state) => state.seqrOps.dirtyState.isDirty);
  const history = useNavigate();
  const location = useLocation();
  const [equipmentClicked, setEquipmentClicked] = useState(false);
  const [allModels, setAllModels] = useState(false);

  const sidebarClass = navigationState.sidebarConsole
    ? "sidebar sidebar--expanded"
    : "sidebar sidbar--collapsed";

  const [searchPhrase, setSearchPhrase] = useState("");

  const search = (event: { target: { value: string } }) => {
    dispatch(assetModelActions.searchByModelName(event.target.value));
    setSearchPhrase(event.target.value);
  };

  const renderAllModels = () => {
    return models.length === 0 ? (
      <div className="seqrops-empty-search-result-message-section">
        <p>no results</p>
      </div>
    ) : (
      <>
        {models.map((modelItem: { id: number; name: string }) => (
          <ModelListItem
            key={modelItem.id}
            targetName={modelItem.name}
            targetId={modelItem.id}
          />
        ))}
      </>
    );
  };

  const renderAllSelectedModels = () => {
    return models.filter((modelItem) => modelItem.isEquipment === true)
      .length === 0 ? (
      <div className="seqrops-empty-search-result-message-section">
        <p>no results</p>
      </div>
    ) : (
      <>
        {models
          .filter((modelItem) => modelItem.isEquipment === true)
          .map((modelItem: { id: number; name: string }) => (
            <ModelListItem
              key={modelItem?.id}
              targetName={modelItem?.name || ""}
              targetId={modelItem?.id || 0}
            />
          ))}
      </>
    );
  };

  const renderAllRootNode = () => {
    return models.filter((modelItem) => modelItem.isRootNode === true)
      .length === 0 ? (
      <div className="seqrops-empty-search-result-message-section">
        <p>no results</p>
      </div>
    ) : (
      <>
        {models
          .filter((modelItem) => modelItem.isRootNode === true)
          .map((modelItem: { id: number; name: string }) => (
            <ModelListItem
              key={modelItem?.id}
              targetName={modelItem?.name || ""}
              targetId={modelItem?.id || 0}
            />
          ))}
      </>
    );
  };

  useEffect(() => {
    if (
      isDirty === false &&
      equipmentClicked === true &&
      location.search === "?equipments/true"
    ) {
      handleEquipment();
    } else if (
      isDirty === false &&
      allModels === true &&
      location.search === "?all-models/true"
    ) {
      handleAllModels();
    }
  }, [isDirty, location]);
  const [filterImage, setFilterImage] = useState(filterChangedIcon);
  const handleRootNodeClick = () => {
    setShowRootNode(true);
    renderAllRootNode();
    setFilterImage(filterIcon);
  };

  const handleEquipment = () => {
    if (isDirty) {
    } else {
      setEquipmentClicked(false);
      setShowRootNode(false);
      setFilterImage(filterChangedIcon);
      dispatch(
        seqrOpsActions.updateNavigationState({
          ...navigationState,
          sidebarShowAllModels: false,
        })
      );
    }
  };

  const handleAllModels = () => {
    if (isDirty) {
    } else {
      setAllModels(false);
      setShowRootNode(false);
      setFilterImage(filterChangedIcon);

      dispatch(
        seqrOpsActions.updateNavigationState({
          ...navigationState,
          sidebarShowAllModels: true,
        })
      );
    }
  };

  const op = useRef<OverlayPanel>(null);
  const [showRootNode, setShowRootNode] = useState(false);
  return (
    <div className={sidebarClass}>
      <button
        className="collapse-btn"
        style={{ width: "100%" }}
        onClick={() => {
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              sidebarConsole: !navigationState.sidebarConsole,
            })
          );
        }}
      >
        <img src={collapsemenu} />
      </button>
      <div className="seqrops-side-bar-console-wrapper">
        <div className="container-fluid px-2 py-3 sidebar_inner">
          {navigationState.sidebarConsole ? (
            <>
              <div className="seqrops-tab">
                <div className="seqrops-side-bar-console-btn-section justify-content-between">
                  <div className="seqrops-side-bar-console-selected-models-list-btn-section w-100">
                    <button
                      className={
                        "br-one " +
                        (!navigationState.sidebarShowAllModels ? "active" : "")
                      }
                      onClick={() => {
                        history(`/?equipments/${isDirty}`);
                        setEquipmentClicked(true);
                        handleEquipment();
                      }}
                    >
                      Equipments
                    </button>
                  </div>

                  <div className="seqrops-side-bar-console-all-models-list-btn-section  w-100">
                    <button
                      className={
                        "br-two " +
                        (navigationState.sidebarShowAllModels ? "active" : "")
                      }
                      onClick={() => {
                        history(`/?all-models/${isDirty}`);
                        setAllModels(true);
                        handleAllModels();
                      }}
                    >
                      All Models
                    </button>
                  </div>
                  {/* <div className="seqrops-side-bar-console-selected-models-list-btn-section w-100">
                    <button
                      className={
                        "br-two " +
                        (!navigationState.sidebarShowAllModels ? "active" : "")
                      }
                      onClick={() => {
                        dispatch(
                          seqrOpsActions.updateNavigationState({
                            ...navigationState,
                            sidebarShowAllModels: false,
                          })
                        );
                      }}
                    >
                      Selected Models
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="seqrops-search-model-section  mt-2">
                <div className="input-group">
                  <span className="input-group-text">
                    <img src={searchImage} alt="seqrops search" />
                  </span>
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search Model"
                    value={searchPhrase}
                    onChange={search}
                  ></input>
                </div>
                <div
                  className="filterIcon"
                  onClick={(e) => op.current && op.current?.toggle(e)}
                >
                  <span className="filterIconSpan">
                    <img
                      // src={filterChangedIcon}
                      src={filterImage}
                      alt="filterIcon"
                    />
                  </span>
                </div>

                <OverlayPanel ref={op} className="overlayFilter">
                  <div className="rootNode" onClick={handleRootNodeClick}>
                    <label>Root node</label>
                  </div>
                </OverlayPanel>
              </div>
            </>
          ) : null}
          <div className="seqrops-model-list-section mt-2">
            {/* {navigationState.sidebarShowAllModels
              ? renderAllModels()
              : renderAllSelectedModels()} */}

            {showRootNode
              ? renderAllRootNode()
              : navigationState.sidebarShowAllModels
              ? renderAllModels()
              : renderAllSelectedModels()}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideBar;
