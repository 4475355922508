import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IModelPropertySlice } from "./model-property.interface";

//initial state
const initialState: IModelPropertySlice = {
  properties: [],
  dynamicProperties: [],
  units: [],
  selectedTreeModelStaticProps: [],
  selectedTreeModelDynamicProps: [],
  selectedTreeModelName: "",
};

const modelPropertySlice = createSlice({
  name: "modelProperties",
  initialState: initialState,
  reducers: {
    setStaticProperties: (state, action: PayloadAction<any>) => {
      state.properties = [...action.payload];
    },
    setDynamicProperties: (state, action: PayloadAction<any>) => {
      state.dynamicProperties = [...action.payload];
    },
    setUnits: (state, action: PayloadAction<any>) => {
      state.units = [...action.payload];
    },
    setSelectedTreeModelStaticProps: (state, action: PayloadAction<any>) => {
      state.selectedTreeModelStaticProps = [...action.payload];
    },
    setSelectedTreeModelDynamicProps: (state, action: PayloadAction<any>) => {
      state.selectedTreeModelDynamicProps = [...action.payload];
    },
    setSelectedTreeModelName: (state, action: PayloadAction<any>) => {
      state.selectedTreeModelName = action.payload;
    },
  },
});

export const { setStaticProperties } = modelPropertySlice.actions;
export const { setDynamicProperties } = modelPropertySlice.actions;
export const { setUnits } = modelPropertySlice.actions;
export const { setSelectedTreeModelStaticProps } = modelPropertySlice.actions;
export const { setSelectedTreeModelDynamicProps } = modelPropertySlice.actions;
export const { setSelectedTreeModelName } = modelPropertySlice.actions;

export default modelPropertySlice.reducer;
