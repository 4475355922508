import { Autocomplete, TextField } from "@mui/material";
import { NAMING_CONVENTION_ERROR_MESSAGE } from "assets/commons/commons";
import axios from "axios";
import ConfirmationDialog from "components/dialogs/confirmation.dialog";
import { ASSET_MODEL_API } from "config/default.config";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Mention, MentionsInput } from "react-mentions";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { modelPropertyActions } from "store/model-properties/model-property.action";
import {
  IAddDynamicPropertyReq,
  IDynamicPropertyDetails,
} from "store/model-properties/model-property.interface";
import { seqrOpsActions } from "store/seqr-ops/seqr-ops.action";
import {
  setDynamicSwitch,
  setIsDirty,
  setIsPublish,
  setLoading,
  setSwitchItem,
} from "store/seqr-ops/seqr-ops.slice";
import AlarmForm from "../alarm/alarm-form";
import "./basic-form.scss";

function BasicForm({
  modelId,
  showDynamicForm,
  setShowDynamicForm,
  setDynamicPropertyFlag,
  dynamicPropertyDetails,
}: {
  modelId: number;
  showDynamicForm: boolean;
  setShowDynamicForm: (isOpen: boolean) => void;
  setDynamicPropertyFlag: (isOpen: boolean) => void;
  dynamicPropertyDetails: IDynamicPropertyDetails | undefined;
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm<IDynamicPropertyDetails>({
    defaultValues: {
      name: "",
      description: "",
      controlMode: "",
      tagMode: "",
      type: "",
      unitId: null,
      dataType: null,
      minValue: null,
      maxValue: null,
      conversionFactorFormula: null,
      computedFormula: null,
      alarms: {
        analog: {
          HIHI: {
            checked: false,
            value: null,
            message: "",
          },
          HI: {
            checked: false,
            value: null,
            message: "",
          },
          LOW: {
            checked: false,
            value: null,
            message: "",
          },
          LOWLOW: {
            checked: false,
            value: null,
            message: "",
          },
        },
        digital: {
          mode: null,
          abnormal: "",
          normal: "",
        },
      },
    },
  });

  const defaultStyle = {
    control: {
      backgroundColor: "#fff",
      fontSize: 14,
      fontWeight: "normal",
      color: "#FF7715",
    },
    highlighter: {
      overflow: "hidden",
    },
    input: {
      margin: 0,
      overflow: "auto",
      height: 30,
    },

    suggestions: {
      bottom: "0",
      top: "unset",
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#60abfb",
        },
      },
    },
  };
  const values = getValues();
  const confirmationPopup = ConfirmationDialog();
  const [unitList, setunitList] = useState([]);
  // const [computedFormula, setComputedFormula] = useState<any>({ value: "", mentions: [] });
  const [computedFormula, setComputedFormula] = useState<any>("");

  const [selectedUnit, setSelectedUnit] = useState<any>(undefined);
  const [isDirty, setIsDirtyForm] = useState(false);
  const isPublish = useAppSelector((state) => state.seqrOps.isPublish);
  const isDirtyForm = useAppSelector(
    (state) => state.seqrOps.dirtyState.isDirty
  );
  const hasChange = useAppSelector((state) => state.seqrOps.hasChange);
  const isModelChange = useAppSelector((state) => state.seqrOps.isModelChange);
  const dynamicSwitch = useAppSelector((state) => state.seqrOps.dynamicSwitch);
  const navigationState = useAppSelector(
    (state) => state.seqrOps.navigationState
  );
  const history = useNavigate();
  const location = useLocation();
  const dynamicPropertyId = dynamicPropertyDetails
    ? dynamicPropertyDetails?.id
    : 0;

  // const dynamicPropertyUnits = useAppSelector(
  //   (state) => state.modelProperties.units
  // );
  // console.log("dynamicPropertyUnits---------", dynamicPropertyUnits);

  const dynamicPropertyListByModelId = useAppSelector(
    (state) => state.modelProperties.dynamicProperties
  );

  const dynamicPropertyName = dynamicPropertyListByModelId.map(
    (dynamicproperty: any) => ({
      id: dynamicproperty.name,
      display: "@" + dynamicproperty.name,
    })
  );

  const thisKeyword = "$" + "this";

  const handleChange = (event: any) => {
    const value = event;
    const regex = /[^{}]+(?=})/g;
    const mentions = computedFormula.match(regex);
    // setComputedFormula({ ...computedFormula, value: event });
    setComputedFormula(value);
  };
  const [isDisableButton, setIsDisableButton] = useState(false);

  useEffect(() => {
    console.log(dynamicSwitch);
    if (isDirtyForm && dynamicSwitch) {
      confirmationPopup.onOpenDialog({
        handleClose() {
          confirmationPopup.closeDialog();
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              propertyConsoleStatic: true,
              propertyConsoleDynamic: false,
            })
          );
          dispatch(setDynamicSwitch(false));
          dispatch(setIsDirty(false));
          dispatch(setSwitchItem("STATIC"));
        },
      });
    }
  }, [dynamicSwitch]);

  useEffect(() => {
    if (isDirtyForm && location.search === "?create-model-edit/true") {
      confirmationPopup.onOpenDialog({
        handleClose() {
          confirmationPopup.closeDialog();
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              propertyConsoleStatic: navigationState.propertyConsoleStatic,
              propertyConsoleDynamic: navigationState.propertyConsoleDynamic,
            })
          );
          dispatch(setIsDirty(false));
        },
      });
    } else if (isDirtyForm && location.search === "?model-delete/true") {
      confirmationPopup.onOpenDialog({
        handleClose() {
          confirmationPopup.closeDialog();
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              propertyConsoleStatic: navigationState.propertyConsoleStatic,
              propertyConsoleDynamic: navigationState.propertyConsoleDynamic,
            })
          );
          dispatch(setIsDirty(false));
        },
      });
    } else if (isDirtyForm && location.search === "?equipments/true") {
      confirmationPopup.onOpenDialog({
        handleClose() {
          confirmationPopup.closeDialog();
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              propertyConsoleStatic: navigationState.propertyConsoleStatic,
              propertyConsoleDynamic: navigationState.propertyConsoleDynamic,
            })
          );
          dispatch(setIsDirty(false));
        },
      });
    } else if (isDirtyForm && location.search === "?all-models/true") {
      confirmationPopup.onOpenDialog({
        handleClose() {
          confirmationPopup.closeDialog();
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              propertyConsoleStatic: navigationState.propertyConsoleStatic,
              propertyConsoleDynamic: navigationState.propertyConsoleDynamic,
            })
          );
          dispatch(setIsDirty(false));
        },
      });
    } else if (isDirtyForm && location.search === "?relation-delete/true") {
      confirmationPopup.onOpenDialog({
        handleClose() {
          confirmationPopup.closeDialog();
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              propertyConsoleStatic: navigationState.propertyConsoleStatic,
              propertyConsoleDynamic: navigationState.propertyConsoleDynamic,
            })
          );
          dispatch(setIsDirty(false));
        },
      });
    } else if (isDirtyForm && location.search === "?relation-create") {
      history("/?relation-create/true");
      confirmationPopup.onOpenDialog({
        handleClose() {
          history("/?relation-create/false");
          confirmationPopup.closeDialog();
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              propertyConsoleStatic: navigationState.propertyConsoleStatic,
              propertyConsoleDynamic: navigationState.propertyConsoleDynamic,
            })
          );
          dispatch(setIsDirty(false));
        },
      });
    }
  }, [isDirtyForm, location.search]);

  useEffect(() => {
    if (hasChange && isDirtyForm) {
      confirmationPopup.onOpenDialog({
        handleClose() {
          confirmationPopup.closeDialog();
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              propertyConsoleStatic: navigationState.propertyConsoleStatic,
              propertyConsoleDynamic: navigationState.propertyConsoleDynamic,
            })
          );
          dispatch(setIsDirty(false));
        },
      });
    }
  }, [hasChange, isDirtyForm]);

  useEffect(() => {
    if (isDirtyForm === true && isPublish === true) {
      confirmationPopup.onOpenDialog({
        handleClose() {
          confirmationPopup.closeDialog();
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              propertyConsoleStatic: navigationState.propertyConsoleStatic,
              propertyConsoleDynamic: navigationState.propertyConsoleDynamic,
            })
          );
          dispatch(setIsDirty(false));
          dispatch(setIsPublish(true));
        },
      });
    }
  }, [isDirty, isPublish]);

  useEffect(() => {
    if (
      isDirtyForm === true &&
      isModelChange !== 0 &&
      isModelChange !== navigationState.selectedModelId
    ) {
      confirmationPopup.onOpenDialog({
        handleClose() {
          confirmationPopup.closeDialog();
          dispatch(
            seqrOpsActions.updateNavigationState({
              ...navigationState,
              propertyConsoleStatic: navigationState.propertyConsoleStatic,
              propertyConsoleDynamic: navigationState.propertyConsoleDynamic,
            })
          );
          dispatch(setIsDirty(false));
        },
      });
    }
  }, [isModelChange, isDirtyForm, navigationState.selectedModelId]);

  // computed formula error section scrolling
  useEffect(() => {
    const element = document.getElementById("error-section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  });

  useEffect(() => {
    axios
      .get(ASSET_MODEL_API.GET_DYNAMIC_PROPERTY_UNIT, {
        params: {
          searchTerm: "",
          page: "0",
          limit: "100",
          sortType: "ASC",
          sortTerm: "name",
        },
      })
      .then((res) => {
        setunitList(res.data.result);
      })
      .catch((err) => console.log(err));
  }, []);

  const errorWrap = (errorMsg: string) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };

  const setSuccess = () => {
    setIsDisableButton(false);
    dispatch(setLoading(false));
    setShowDynamicForm(false);
    setDynamicPropertyFlag(false);
    handleCancel();
  };

  const dispatch = useAppDispatch();

  const computedFormulaValidation = (
    propertyValues: IDynamicPropertyDetails
  ) => {
    if (propertyValues.type === "COMPUTED") {
      const regex = /^([\d@].*|@\w+.*$)/;
      const formula = computedFormula?.replace(/[\[\]']+/g, "");
      // if (!regex.test(formula)) {
      //   setError("computedFormula", {
      //     type: "custom",
      //     message: "Formula not valid",
      //   });
      //   return false;
      // }
    }
    return true;
  };

  const nameValidation = (propertyValues: IDynamicPropertyDetails) => {
    if (
      propertyValues.name.toLowerCase() === "name" ||
      propertyValues.name.toLowerCase() === "description" ||
      propertyValues.name.toLowerCase() === "createdat" ||
      propertyValues.name.toLocaleLowerCase() === "updatedat"
    ) {
      setError("name", {
        type: "custom",
        message: "Reserved name cannot be used, please try a new name",
      });
      return false;
    }
    if (!/^(?!_)(?!.*_$)\w+$/.test(propertyValues.name)) {
      setError("name", {
        type: "custom",
        message: "Property name must not start or end with underscore",
      });
      return false;
    }
    return true;
  };

  const minValidation = (propertyValues: IDynamicPropertyDetails) => {
    if (propertyValues.type === "ANALOG") {
      if (Number(propertyValues.minValue) >= Number(propertyValues.maxValue)) {
        setError("minValue", {
          type: "custom",
          message: "MinValue should be less than MaxValue",
        });
      } else {
        return true;
      }
    } else {
      return true;
    }
    return false;
  };

  const minMaxValidation = (propertyValues: IDynamicPropertyDetails) => {
    var er = /^-?[0-9]+$/;

    if (
      (propertyValues.type === "ANALOG" ||
        propertyValues.type === "COMPUTED") &&
      (propertyValues.dataType === "INTEGER" ||
        propertyValues.dataType === "LONG")
    ) {
      let dataType = propertyValues.dataType;
      let maxError;
      let minError;
      if (
        propertyValues.minValue !== null &&
        !er.test(propertyValues.minValue.toString())
      ) {
        setError("minValue", {
          type: "custom",
          message: "Min value should be " + dataType.toLowerCase(),
        });
        minError = true;
      }
      if (
        propertyValues.maxValue !== null &&
        !er.test(propertyValues.maxValue.toString())
      ) {
        setError("maxValue", {
          type: "custom",
          message: "Max value should be " + dataType.toLowerCase(),
        });
        maxError = true;
      }
      if (maxError || minError) {
        return false;
      }
    }
    return true;
  };

  const validateAnalogAlarm = (propertyValues: IDynamicPropertyDetails) => {
    const highValues = [];
    if (propertyValues.alarms.analog.HIHI.checked) {
      highValues.push({
        name: "HIHI",
        value: Number(propertyValues.alarms.analog.HIHI.value || 0),
      });
    }
    if (propertyValues.alarms.analog.HI.checked) {
      highValues.push({
        name: "HI",
        value: Number(propertyValues.alarms.analog.HI.value || 0),
      });
    }

    /** Validate high values */
    if (highValues.length === 2) {
      if (highValues[0].value <= highValues[1].value) {
        setError("alarms.analog.HIHI.value", {
          type: "custom",
          message: "HIHI should be greater than HI",
        });
        return false;
      }
    }

    const lowValues = [];
    if (propertyValues.alarms.analog.LOW.checked) {
      lowValues.push({
        name: "LOW",
        value: Number(propertyValues.alarms.analog.LOW.value || 0),
      });
    }
    if (propertyValues.alarms.analog.LOWLOW.checked) {
      lowValues.push({
        name: "LOWLOW",
        value: Number(propertyValues.alarms.analog.LOWLOW.value || 0),
      });
    }
    /** Validate low values */
    if (lowValues.length === 2) {
      if (lowValues[0].value <= lowValues[1].value) {
        setError("alarms.analog.LOW.value", {
          type: "custom",
          message: "LOW should be greater than LOWLOW",
        });
        return false;
      }
    }

    /** Validate high - low values */
    if (highValues.length > 0 && lowValues.length > 0) {
      if (highValues[highValues.length - 1].value <= lowValues[0].value) {
        const field: any =
          "alarms.analog." + highValues[highValues.length - 1].name + ".value";
        setError(field, {
          type: "custom",
          message:
            highValues[highValues.length - 1].name +
            " should be greater than " +
            lowValues[0].name,
        });
        return false;
      }
    }

    /**Validate high with min max value */
    if (highValues.length > 0) {
      for (const element of highValues) {
        if (element.value > Number(propertyValues.maxValue)) {
          const field: any = "alarms.analog." + element.name + ".value";
          setError(field, {
            type: "custom",
            message:
              element.name +
              " should be less than maxvalue " +
              propertyValues.maxValue,
          });
          return false;
        }

        if (element.value < Number(propertyValues.minValue)) {
          const field: any = "alarms.analog." + element.name + ".value";
          setError(field, {
            type: "custom",
            message:
              element.name +
              " should be greater than minValue " +
              propertyValues.minValue,
          });
          return false;
        }
      }
    }

    /**Validate low with min max values */
    if (lowValues.length > 0) {
      for (const element of lowValues) {
        if (element.value < Number(propertyValues.minValue)) {
          const field: any = "alarms.analog." + element.name + ".value";
          setError(field, {
            type: "custom",
            message:
              element.name +
              " should be greater than minValue " +
              propertyValues.minValue,
          });
          return false;
        }
        if (element.value > Number(propertyValues.maxValue)) {
          const field: any = "alarms.analog." + element.name + ".value";
          setError(field, {
            type: "custom",
            message:
              element.name +
              " should be less than maxvalue " +
              propertyValues.maxValue,
          });
          return false;
        }
      }
    }
    return true;
  };

  const formatReqBody = (
    propertyValues: IDynamicPropertyDetails
  ): IAddDynamicPropertyReq => {
    const propertyId = dynamicPropertyDetails?.id || 0;

    const alarms: any = [];
    if (
      propertyValues.type === "ANALOG" ||
      propertyValues.type === "COMPUTED"
    ) {
      if (propertyValues.alarms.analog.HIHI.checked) {
        alarms.push({
          id: null,
          alarmType: "HIHI",
          color: "string",
          value: propertyValues.alarms.analog.HIHI.value,
          message: propertyValues.alarms.analog.HIHI.message,
        });
      }
      if (propertyValues.alarms.analog.HI.checked) {
        alarms.push({
          id: null,
          alarmType: "HI",
          color: "string",
          value: propertyValues.alarms.analog.HI.value,
          message: propertyValues.alarms.analog.HI.message,
        });
      }
      if (propertyValues.alarms.analog.LOW.checked) {
        alarms.push({
          id: null,
          alarmType: "LOW",
          color: "string",
          value: propertyValues.alarms.analog.LOW.value,
          message: propertyValues.alarms.analog.LOW.message,
        });
      }
      if (propertyValues.alarms.analog.LOWLOW.checked) {
        alarms.push({
          id: null,
          alarmType: "LOWLOW",
          color: "string",
          value: propertyValues.alarms.analog.LOWLOW.value,
          message: propertyValues.alarms.analog.LOWLOW.message,
        });
      }
    } else if (propertyValues.type === "DIGITAL") {
      if (propertyValues.alarms.digital.mode?.toString() === "0") {
        alarms.push({
          id: null,
          alarmType: "ABNORMAL",
          value: propertyValues.alarms.digital.mode,
          message: propertyValues.alarms.digital.abnormal,
        });
        alarms.push({
          id: null,
          alarmType: "NORMAL",
          value: propertyValues.alarms.digital.mode,
          message: propertyValues.alarms.digital.normal,
        });
      } else if (propertyValues.alarms.digital.mode?.toString() === "1") {
        alarms.push({
          id: null,
          alarmType: "NORMAL",
          value: propertyValues.alarms.digital.mode,
          message: propertyValues.alarms.digital.normal,
        });
        alarms.push({
          id: null,
          alarmType: "ABNORMAL",
          value: propertyValues.alarms.digital.mode,
          message: propertyValues.alarms.digital.abnormal,
        });
      }
    }

    /** CREATE REQ BODY */
    const reqBody: IAddDynamicPropertyReq = {
      id: propertyId,
      name: propertyValues.name,
      description: propertyValues.description,
      controlMode: propertyValues.controlMode,
      tagMode: propertyValues.tagMode,
      type: propertyValues.type,
      unitId: propertyValues.unitId,
      dataType: propertyValues.dataType,
      minValue: propertyValues.minValue,
      maxValue: propertyValues.maxValue,
      conversionFactorFormula: propertyValues.conversionFactorFormula,
      computedFormula: propertyValues.computedFormula?.replace(/[\[\]']+/g, ""),
      modelId: modelId,
      alarms: alarms,
    };

    return reqBody;
  };

  // SAVE
  const handleSave = (propertyValues: IDynamicPropertyDetails) => {
    dispatch(setLoading(true));
    setIsDisableButton(true);
    if (
      !validateAnalogAlarm(propertyValues) ||
      !nameValidation(propertyValues) ||
      !minValidation(propertyValues) ||
      !minMaxValidation(propertyValues) ||
      !computedFormulaValidation(propertyValues)
    ) {
      dispatch(setLoading(false));
      setIsDisableButton(false);
      return;
    }

    if (propertyValues.type === "DIGITAL" && propertyValues.unitId !== null) {
      propertyValues.unitId = null;
      propertyValues.dataType = null;
      propertyValues.computedFormula = null;
      propertyValues.conversionFactorFormula = null;
      propertyValues.minValue = null;
      propertyValues.maxValue = null;
    }
    /** CREATE REQ BODY */
    const reqBody = formatReqBody(propertyValues);

    /** CALL API */
    if (dynamicPropertyId === 0) {
      dispatch(
        modelPropertyActions.addDynamicProperty(
          reqBody,
          setError,
          setSuccess,
          setLoading,
          setIsDisableButton
        )
      );
    } else {
      dispatch(
        modelPropertyActions.updateDynamicProperty(
          reqBody.id,
          reqBody,
          setError,
          setSuccess,
          setLoading,
          setIsDisableButton
        )
      );
    }
    setIsDirtyForm(false);
  };

  const handleFormChange = () => {
    setIsDirtyForm(true);
    return dispatch(setIsDirty(true));
  };

  const handleCancel = () => {
    reset();
    dispatch(setIsDirty(false));
    setShowDynamicForm(false);
    setDynamicPropertyFlag(false);

    confirmationPopup.closeDialog();
  };

  useEffect(() => {
    if (dynamicPropertyDetails && unitList.length > 0) {
      setValue("id", dynamicPropertyDetails.id);
      setValue("name", dynamicPropertyDetails.name);
      setValue("description", dynamicPropertyDetails.description);
      setValue("controlMode", dynamicPropertyDetails.controlMode);
      setValue("tagMode", dynamicPropertyDetails.tagMode);
      setValue("type", dynamicPropertyDetails?.type);

      const unit = unitList.find(
        (x: { id: number }) => x.id === dynamicPropertyDetails.unitId
      );
      setSelectedUnit(unit);
      setValue("unitId", dynamicPropertyDetails.unitId);

      setValue("dataType", dynamicPropertyDetails.dataType);
      setValue("minValue", dynamicPropertyDetails.minValue);
      setValue("maxValue", dynamicPropertyDetails.maxValue);
      setValue(
        "conversionFactorFormula",
        dynamicPropertyDetails.conversionFactorFormula
      );
      // setComputedFormula(
      //   dynamicPropertyDetails.computedFormula != null
      //     ? dynamicPropertyDetails.computedFormula
      //     : ""
      // );
      if (dynamicPropertyDetails.computedFormula !== null) {
        const regex = /[@$]\w+/g;
        const inputString: any = dynamicPropertyDetails.computedFormula;
        const outputString = inputString.replace(
          regex,
          (match: any) => `[${match}]`
        );
        setComputedFormula(
          dynamicPropertyDetails.computedFormula != null ? outputString : ""
        );
      }

      setValue("computedFormula", dynamicPropertyDetails.computedFormula);
      setValue("modelId", dynamicPropertyDetails.modelId);
      if (
        dynamicPropertyDetails.type === "ANALOG" ||
        dynamicPropertyDetails.type === "COMPUTED"
      ) {
        let analog_alarms: any = dynamicPropertyDetails.alarms;
        for (const element of analog_alarms) {
          if (element.alarmType === "HIHI") {
            // HIHI value setting
            setValue("alarms.analog.HIHI.checked", true);

            setValue("alarms.analog.HIHI.value", element.value);
            setValue("alarms.analog.HIHI.message", element.message);
          }
          if (element.alarmType === "HI") {
            // HI value setting
            setValue("alarms.analog.HI.checked", true);
            setValue("alarms.analog.HI.value", element.value);
            setValue("alarms.analog.HI.message", element.message);
          }
          if (element.alarmType === "LOW") {
            // LOW value setting
            setValue("alarms.analog.LOW.checked", true);
            setValue("alarms.analog.LOW.value", element.value);
            setValue("alarms.analog.LOW.message", element.message);
          }
          if (element.alarmType === "LOWLOW") {
            //LOWLOW value setting
            setValue("alarms.analog.LOWLOW.checked", true);
            setValue("alarms.analog.LOWLOW.value", element.value);
            setValue("alarms.analog.LOWLOW.message", element.message);
          }
        }
      } else if (dynamicPropertyDetails.type === "DIGITAL") {
        let digital_alarms: any = dynamicPropertyDetails.alarms;
        for (const element of digital_alarms) {
          setValue("alarms.digital.mode", element.value);
          if (element.alarmType === "NORMAL") {
            setValue("alarms.digital.normal", element.message);
          } else if (element.alarmType === "ABNORMAL") {
            setValue("alarms.digital.abnormal", element.message);
          }
        }
      }
    }
    if (dynamicPropertyDetails?.type == "DIGITAL") {
      setValue("type", dynamicPropertyDetails?.type);
    }
  }, [dynamicPropertyDetails, unitList]);

  if (!showDynamicForm) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="seqrops-property-adding-section">
          <div className="seqrops-basic-form-wrapper">
            <div className="seqrops-basic-form-head-section">
              <p>Basic</p>
            </div>
            <div className="container-fluid">
              <div className="seqrops-basic-form-section">
                <div className="row">
                  <div className="dynamicPropertyName">
                    <label className="form-label" htmlFor="name">
                      Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      autoFocus
                      minLength={3}
                      maxLength={50}
                      {...register("name", {
                        required: "Please enter name",
                        pattern: {
                          value: /^\w*$/,
                          // value: /^[^_/.\s]\w*$/,
                          message: NAMING_CONVENTION_ERROR_MESSAGE,
                        },

                        minLength: {
                          value: 3,
                          message:
                            "Property name should contain minimum 3 characters",
                        },
                        maxLength: {
                          value: 50,
                          message:
                            "Property name should contain only maximum 50 characters",
                        },
                      })}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue("name", e.target.value.replace(/\W/g, ""));
                        clearErrors("name");
                        handleFormChange();
                      }}
                    />
                  </div>{" "}
                  {errorWrap(errors?.name?.message || "")}
                  <div className="description col-md-12">
                    <label className="form-label" htmlFor="description">
                      Description
                    </label>
                    <textarea
                      className="description-input form-control"
                      id="description"
                      rows={5}
                      cols={40}
                      minLength={3}
                      maxLength={256}
                      {...register("description", {
                        required: "Please add a description",
                        minLength: {
                          value: 3,
                          message:
                            "Model description should contain minimum 3 characters",
                        },
                        maxLength: {
                          value: 250,
                          message:
                            "Model description should contain only maximum 250 characters",
                        },
                      })}
                      onChange={(e) => {
                        setValue("description", e.target.value);
                        clearErrors("description");
                        handleFormChange();
                      }}
                    ></textarea>
                  </div>
                  <>{errorWrap(errors?.description?.message || "")}</>
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="controlMode">
                      Control Mode
                    </label>
                    <select
                      {...register("controlMode", {
                        required: "Please select Control Mode",
                      })}
                      onChange={(e: any) => {
                        if (errors?.controlMode) {
                          clearErrors("controlMode");
                        }
                        setValue("controlMode", e.target.value, {
                          shouldValidate: true,
                        });
                        handleFormChange();
                      }}
                      className="form-select"
                    >
                      <option value="AUTO">Auto</option>
                      <option value="MANUAL">Manual</option>
                    </select>
                    {errorWrap(errors?.controlMode?.message || "")}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="tagMode">
                      Tag Mode
                    </label>
                    <select
                      {...register("tagMode", {
                        required: "Please select  Tag Mode",
                      })}
                      onChange={(e: any) => {
                        if (errors?.tagMode) {
                          clearErrors("tagMode");
                        }
                        setValue("tagMode", e.target.value, {
                          shouldValidate: true,
                        });
                        handleFormChange();
                      }}
                      className="form-select"
                    >
                      <option value="MONITOR">Monitor</option>
                      <option value="CONTROL">Control</option>
                      <option value="MONITOR_AND_CONTROL">
                        Monitor and Control
                      </option>
                    </select>
                    {errorWrap(errors?.tagMode?.message || "")}
                  </div>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="type">
                      Type
                    </label>
                    <select
                      className="form-select"
                      {...register("type", { required: "Please select type" })}
                      onChange={(e: any) => {
                        if (errors?.type) {
                          clearErrors("type");
                        }
                        setValue("type", e.target.value, {
                          shouldValidate: true,
                        });
                        handleFormChange();
                      }}
                    >
                      <option value="ANALOG">Analog</option>
                      <option value="DIGITAL">Digital</option>
                      <option value="COMPUTED">Computed</option>
                    </select>
                    {errorWrap(errors?.type?.message || "")}
                  </div>
                  {values.type !== "DIGITAL" && values.type !== "" && (
                    <>
                      <div className="analog-values row col-md-12 m-auto p-0">
                        <div className="col-md-6 unitDropDown">
                          <label className="form-label" htmlFor="unit">
                            Unit
                          </label>
                          <Autocomplete
                            className="unitAutocomplete"
                            // className="min-input"
                            // id="seqrops-assosiation-selecting-dropdown"
                            {...register("unitId", {
                              required: "Please select unit",
                            })}
                            disableClearable
                            defaultValue={selectedUnit}
                            options={unitList}
                            onChange={(e, data) => {
                              setValue("unitId", data.id);
                              clearErrors("unitId");
                              setSelectedUnit(data);
                              handleFormChange();
                            }}
                            getOptionLabel={(option: any) => option.name}
                            renderInput={(params: any) => {
                              return (
                                <TextField
                                  className="form-select "
                                  {...params}
                                  key={params.id}
                                  label=""
                                />
                              );
                            }}
                          />
                          {errorWrap(errors?.unitId?.message || "")}
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" htmlFor="dataType">
                            DataType
                          </label>
                          <select
                            className="form-select"
                            {...register("dataType", {
                              required: "Please select data type",
                            })}
                            onChange={(e) => {
                              if (errors?.dataType) {
                                clearErrors("dataType");
                              }
                              setValue("dataType", e.target.value, {
                                shouldValidate: true,
                              });
                              handleFormChange();
                            }}
                          >
                            <option value="DOUBLE">Double</option>
                            <option value="FLOAT">Float</option>
                            <option value="INTEGER">Integer</option>
                            <option value="LONG">Long</option>
                          </select>
                          {errorWrap(errors?.dataType?.message || "")}
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" htmlFor="MinValue">
                            Min
                          </label>
                          <input
                            className="min-input form-control"
                            type="text"
                            id="minValue"
                            {...register("minValue", {
                              required: "Please enter min value",
                            })}
                            onChange={(e) => {
                              if (errors?.minValue) {
                                clearErrors("minValue");
                              }
                              handleFormChange();
                            }}
                          />
                          {errorWrap(errors?.minValue?.message || "")}
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" htmlFor="MaxValue">
                            Max
                          </label>
                          <input
                            className="max-input form-control"
                            type="text"
                            {...register("maxValue", {
                              required: "Please enter max value",
                            })}
                            onChange={(e) => {
                              if (errors?.maxValue) {
                                clearErrors("maxValue");
                              }
                              handleFormChange();
                            }}
                          />
                          {errorWrap(errors?.maxValue?.message || "")}
                        </div>
                        <div className="conversion-factor-formula col-md-12">
                          <label
                            className="form-label"
                            htmlFor="conversionFactorFormula"
                          >
                            Conversion Factor Formula
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="conversionFactorFormula"
                            {...register("conversionFactorFormula", {})}
                            onChange={(e) => {
                              setValue(
                                "conversionFactorFormula",
                                e.target.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                              handleFormChange();
                            }}
                          />
                        </div>
                        {values.type == "COMPUTED" && (
                          <>
                            <div id="error-section">
                              <label
                                className="form-label"
                                htmlFor="computedFormula"
                              >
                                Computed Formula
                              </label>
                              <div className="computed-list-section">
                                <MentionsInput
                                  className="form-control"
                                  style={defaultStyle}
                                  id="computedFormula"
                                  value={computedFormula}
                                  {...register("computedFormula", {})}
                                  onChange={(e) => {
                                    // if (computedFormula.charAt(computedFormula.length - 1) === e.target.value.charAt(e.target.value.length - 1)) {
                                    //   return;
                                    // } else {
                                    handleChange(e.target.value);
                                    if (errors?.computedFormula) {
                                      clearErrors("computedFormula");
                                    }
                                    setValue("computedFormula", e.target.value);
                                    // }
                                    handleFormChange();
                                  }}
                                >
                                  <Mention
                                    markup="[__display__]"
                                    data={dynamicPropertyName}
                                    trigger={"@"}
                                    appendSpaceOnAdd={true}
                                  />
                                  <Mention
                                    markup="[__display__]"
                                    data={[{ id: 0, display: thisKeyword }]}
                                    trigger="$"
                                    appendSpaceOnAdd={true}
                                  />
                                </MentionsInput>
                              </div>
                              <>
                                {errorWrap(
                                  errors?.computedFormula?.message || ""
                                )}
                              </>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="seqrops-property-adding-section mt-3">
          <AlarmForm
            register={register}
            errors={errors}
            clearErrors={clearErrors}
            values={values}
            setValue={setValue}
          />
        </div>

        <div className="seqrops-property-adding-btn-section col-md-12 my-2 p-0 py-1 px-1">
          <button
            type="button"
            className="seqrops-btn btn-outline me-2 w-custom-btn"
            onClick={() => {
              if (isDirty === true) {
                confirmationPopup.onOpenDialog({
                  handleClose() {
                    handleCancel();
                  },
                });
              } else {
                reset();
                setShowDynamicForm(false);
                setDynamicPropertyFlag(false);
              }
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="seqrops-btn btn-fill w-custom-btn"
            onClick={handleSubmit(handleSave)}
            disabled={isDisableButton}
          >
            {dynamicPropertyDetails?.id != null ? "Update" : "Add"}
          </button>
        </div>
      </form>
    </>
  );
}

export default BasicForm;
