import Navbar from "components/navbar.component/navbar";
import LandingScreen from "modules/landing-screen/landing-screen";
import ViewConsole from "modules/view-console/view-console";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import "./default-container.layout.scss";

import { SESSION_KEY } from "config/default.config";
import SideBar from "modules/sidebar-console/sidebar-console";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { DndProvider } from "react-dnd-multi-backend";
import { assetModelActions } from "store/asset-model/asset-model.action";
import { IUserAuthorizations } from "store/keycloak/asset-keycloak.interface";
import { sessionStorageUtils } from "utils/session-storage.utils";

function SeqrOpsLTR({ modelsCount }: { modelsCount: number }) {
  const [loggedUser, setLoggedUser] = useState<string>();
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const dispatch = useAppDispatch();
  const navigationState = useAppSelector(
    (state) => state.seqrOps.navigationState
  );

  useEffect(() => {
    if (
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
      null
    ) {
      let tokenData: any = sessionStorageUtils.getLocalStorage(
        SESSION_KEY.LOCAL_STORAGE_KEY
      );
      setLoggedUser(tokenData?.token?.username);
      setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);
    }

    dispatch(assetModelActions.getModelList());
  }, [dispatch]);

  if (modelsCount === 0) {
    return (
      <div className="seqrops-english-layout-wrapper">
        <div className="container-fluid">
          <div className="row seqrops-english-navbar-section">
            <Navbar
              loggeduser={
                userScopesFromLocalStorage !== undefined ? loggedUser : "Admin"
              }
            />
          </div>
          <LandingScreen />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="seqrops-english-layout-wrapper">
        <div className="container-fluid">
          <div className="row seqrops-english-navbar-section">
            <Navbar
              loggeduser={
                userScopesFromLocalStorage !== undefined ? loggedUser : "Admin"
              }
            />
          </div>
          <div className="console-area-container">
            <DndProvider options={HTML5toTouch}>
              <div className="side-bar-console-section">
                <SideBar />
              </div>
              <div
                className={
                  navigationState.sidebarConsole
                    ? "console-area-main-content console-area-main-content--expanded"
                    : "console-area-main-content"
                }
              >
                <ViewConsole />
              </div>
            </DndProvider>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeqrOpsLTR;
