import axios from "axios";
import { ASSET_MODEL_API } from "config/default.config";
import { useEffect, useState } from "react";
import { FieldErrors } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import {
  IStaticPropertyDetails,
  IUnit,
} from "store/model-properties/model-property.interface";
import { setIsDirty } from "store/seqr-ops/seqr-ops.slice";
import { STATIC_PROPERTY_MESSAGES } from "../static-property-validation";

function UnitComponent({
  getValues,
  register,
  setError,
  setValue,
  handleUnitChange,
  errorWrap,
  errors,
  staticPropertyDetails,
}: {
  getValues: any;
  register: any;
  setError: any;
  setValue: any;
  handleUnitChange: (unit: number) => void;
  errorWrap: (code: string | undefined) => void;
  errors: FieldErrors<IStaticPropertyDetails>;
  staticPropertyDetails: IStaticPropertyDetails | undefined;
}) {
  const staticProperties = useAppSelector(
    (state) => state.modelProperties.properties
  );
  const dispatch = useDispatch();
  const [unitList, setunitList] = useState<IUnit[]>([]);
  const [unitValue, setUnitValue] = useState<number>();
  useEffect(() => {
    if (
      staticPropertyDetails?.unit != undefined &&
      staticPropertyDetails?.unit != null
    ) {
      setUnitValue(staticPropertyDetails?.unit);
    }
    axios
      .get(ASSET_MODEL_API.GET_STATIC_PROPERTY_UNIT, {
        params: {
          searchTerm: "",
          page: "0",
          limit: "100",
          sortType: "ASC",
          sortTerm: "name",
        },
      })
      .then((res) => {
        setunitList(res.data.result);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <label className="form-label" htmlFor="PropetyUnit">
        Unit
      </label>
      <select
        className="form-select"
        {...register("unit", {
          required: STATIC_PROPERTY_MESSAGES.UNIT_REQUIRED,
        })}
        onChange={(e) => {
          const selectedValue = e.target.value;
          const numericValue = parseFloat(selectedValue);
          const currentUnit = unitList.find((unit) => unit.id === numericValue);
          setUnitValue(currentUnit?.id);

          handleUnitChange(numericValue);
          dispatch(setIsDirty(true));

          if (e.target.value === "DATETIME") {
            setValue("defaultValue", "Select");
            dispatch(setIsDirty(true));
          }
        }}
        disabled={staticPropertyDetails?.isReserved}
        value={unitValue != undefined && unitValue != null ? unitValue : ""}
      >
        <option value=""></option>
        {unitList
          .filter((unit) => !unit.name.includes(","))
          .map((unit: IUnit) => (
            <option key={unit?.id} value={unit?.id}>
              {unit?.name}
            </option>
          ))}
      </select>

      <>{errorWrap(errors?.unit?.message)}</>
    </>
  );
}

export default UnitComponent;
