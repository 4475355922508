//API URl

export const BASE_URL = `${process.env.REACT_APP_MODEL_API_URL}`;
export const ASSETS_URL = `${process.env.REACT_APP_ASSETS_API_URL}`;
export const TAG_URL = `${process.env.REACT_TAG_SERVICE_API_URL}`;
export const FILE_BASE_URL = `${process.env.REACT_APP_FILE_API_URL}`;
export const UNIT_BASE_URL = `${process.env.REACT_APP_UNIT_API_URL}`;
export const TAG_BASE_URL = `${process.env.REACT_APP_TAG_API_URL}`;
export const KEYCLOAK_BASE_URL = `${process.env.REACT_APP_KEYCLOAK_API_URL}`;
export const AUTH_BASE_URL = `${process.env.REACT_APP_AUTH_API_URL}`;
export const ASSET_SUITE_LOGIN_URL = `${process.env.REACT_APP_SUITE_URL}`;
export const CONTRACT_URL = `${process.env.REACT_APP_CONTRACT_URL}`;
export const ASSET_URLS = {
  ASSET_SUITE_URL: `${process.env.REACT_APP_SUITE_URL}dashboard`,
  ASSET_MODELS_URL: `${process.env.REACT_APP_MODELS_URL}`,
  ASSET_MANAGEMENT_URL: `${process.env.REACT_APP_ASSETS_URL}`,
  DEVICE_MANAGEMENT_URL: `${process.env.REACT_APP_DEVICE_MANAGEMENT_URL}#/devices`,
  FIELD_SERVICE_MANAGEMENT: `${process.env.REACT_APP_FSM_URL}`,
  OPERATIONAL_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  MANAGEMENT_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  CONTRACT_URL: `${process.env.REACT_APP_CONTRACT_URL}/contracts`,
  MONITORING_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  GRID_MONOTORING_APP: `${process.env.REACT_APP_GRID_MONOTORING_APP}`,
  ANALYTICS_DASHBOARD: `${process.env.REACT_APP_ANALYTICS_DASHBOARD}`,
};

export const COOKIE_DETAILS = {
  COOKIE_NAME: `${process.env.REACT_APP_COOKIE_PREFIX}_user_session_id`,
  COOKIE_DOMAIN: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
};

export const PERMITED_DOMAINS = [
  `${process.env.REACT_APP_APP_SUITE_LOCAL_STORAGE_URL}`,
  `${process.env.REACT_APP_MODELS_LOCAL_STORAGE_URL}`,
  `${process.env.REACT_APP_ASSETS_LOCAL_STORAGE_URL}`,
  `${process.env.REACT_APP_ADMIN_LOCAL_STORAGE_URL}`,
  `${process.env.REACT_APP_FSM_LOCAL_STORAGE_URL}`,
];

export const ASSET_AUTH_API = {
  GET_LOGIN_TOKEN: AUTH_BASE_URL + "api/v1/auth/token",
  VERIFY_LOGIN_TOKEN: AUTH_BASE_URL + "api/v1/auth/token/introspect",
  LOGOUT_TOKEN: AUTH_BASE_URL + "api/v1/auth/logout",
};

export const IFRAME_ID = {
  SUITE_IFRAME_ID: "ifr_suite",
  MODELS_IFRAME_ID: "ifr_models",
  ASSETS_IFRAME_ID: "ifr_assets",
  DEVICE_MANAGEMENET_IFRAME_ID: "ifr_device",
  FSM_IFRAME_ID: "ifr_fsm",
  DASHBOARD_IFRAME_ID: "ifr_dashboard",
};

export const SESSION_TYPE = {
  USER_LOGIN_TYPE: "1",
  USER_LOGOUT_TYPE: "0",
  DOMAIN_PASS_TYPE: "2",
};

export const THEME_CHANGE_URL = {
  THEME_CHANGE:
    "https://dtech-api.seqrops.in/oauth/api/v1/attributes/set-theme",
};

//Asset resource scopes (keycloak)
export const ASSET_SCOPES = [
  "asset-management",
  "asset-models",
  "device-management",
  "asset-suite",
  "field-service-management",
  "operational-dashboard",
  "management-dashboard",
  "asset-contract",
  "monitoring-dashboard",
  "grid-monitoring-app",
  "analytics-dashboard",
];
export const ASSET_MANAGEMENT_SCOPE = "asset-management";
export const ASSET_SUITE_SCOPE = "asset-suite";
export const ASSET_MODELS_SCOPE = "asset-models";
export const DEVICE_MANAGEMENET_SCOPE = "device-management";
export const FIELD_SERVICE_MANAGEMENT = "field-service-management";
export const MANAGEMENT_DASHBOARD = "operational-dashboard";
export const OPERATIONAL_DASHBOARD = "management-dashboard";

export const ASSET_MODEL_API = {
  DOMAIN_DETAILS: BASE_URL + "seqrops/api/v1/domain",
  // Model CRUD
  GET_MODELS_COUNT: BASE_URL + "api/v1/model/count",
  CREATE_MODEL: BASE_URL + "api/v1/model/createModel",
  UPDATE_MODEL: BASE_URL + "api/v1/model/updateModel",
  GET_MODEL_BY_ID: BASE_URL + "api/v1/model/getById/{0}",
  GET_ALL_MODELS: BASE_URL + "api/v1/model/all",
  GET_MODEL_HIERARCHY: BASE_URL + "api/v1/model/hierarchy/{0}",
  DELETE_MODEL: BASE_URL + "api/v1/model/delete/{0}",
  INITIATE_DELETE_MODEL:
    BASE_URL + "api/v1/model/checkIfModelRelationsExist/{0}",
  PUBLISH_MODEL: BASE_URL + "api/v1/data/publish/{0}",
  SET_DOMAIN: BASE_URL + "api/v1/model/set-domain-status/{0}",
  GET_PUBLISH_HISTORY: BASE_URL + "api/v1/model/getPublishHistory",

  CREATE_RELATION: BASE_URL + "seqrops/api/v1/relationship/createRelation",
  DELETE_MODEL_RELATION:
    BASE_URL + "seqrops/api/v1/relationship/deleteEdgeRelation/{0}",
  GET_ALL_STATIC_PROPERTIES: BASE_URL + "api/v1/model/{0}/property/all",
  ADD_STATIC_PROPERTY: BASE_URL + "api/v1/model/property/createProperty",
  UPDATE_STATIC_PROPERTY: BASE_URL + "api/v1/model/property/updateProperty/{0}",
  DELETE_STATIC_PROPERTY: BASE_URL + "api/v1/model/property/deleteProperty/{0}",

  GET_PROPERTY_DETAILS_BY_ID: BASE_URL + "api/v1/model/property/getById/{0}",
  ADD_DYNAMIC_PROPERTY:
    BASE_URL + "/api/v1/model/dynamicProperty/createDynamicProperty",
  GET_DYNAMIC_PROPERTY:
    BASE_URL + "/api/v1/model/dynamicProperty/getDynamicProperty/{0}",

  REFERENCE_URL: BASE_URL + "api/v1/model/file/reference",

  UPLOAD_FILE_PROPERTY: FILE_BASE_URL + "api/v1/file-management/upload",
  REPLACE_FILE_PROPERTY: FILE_BASE_URL + "api/v1/file-management/replace",
  DELETE_FILE_PROPERTY: FILE_BASE_URL + "api/v1/file-management/delete/{0}",

  UPDATE_STATIC_FILE_PROPERTY:
    BASE_URL + "api/v1/model/property/updateFileProperty/{0}",
  DOWNLOAD_STATIC_PROPERTY: FILE_BASE_URL + "api/v1/file-management/download/",
  GET_DYNAMIC_PROPERTY_UNIT: UNIT_BASE_URL + "api/v1/unit/",
  GET_STATIC_PROPERTY_UNIT: TAG_BASE_URL + "api/v1/tag/unit",
  UPDATE_DYNAMIC_PROPERTY:
    BASE_URL + "api/v1/model/dynamicProperty/updateDynamicProperty",
  GET_DYNAMIC_PROPERTY_BY_ID:
    BASE_URL + "api/v1/model/dynamicProperty/getDynamicPropertyById/{0}",
  DELETE_DYNAMIC_PROPERTY: BASE_URL + "api/v1/model/dynamicProperty/delete/{0}",
  // GET_FILE_PROPERTY_BY_ID: FILE_BASE_URL + "api/v1/file-management/file/{id}",
};

export const ASSET_MANAGEMENT_API = {
  GET_INSTANCE_COUNT: ASSETS_URL + "api/v1/instance/getInstanceCount/",
  CHECK_PROPERTY_TYPE_CHANGE_API:
    ASSETS_URL + "api/v1/instance/check-property-type-change",
};

export const KEYCLOAK_GRANT_TYPES = {
  PASSWORD: "password",
  CLIENT_CREDENTIALS: "client_credentials",
  REFRESH_TOKENS: "refresh_token",
};

export const KEYCLOAK_CLIENTS = {
  ASSET_SUITE: "asset-suite",
  ASSET_MANAGEMENT: "asset-management",
  ASSET_MODELS: "asset-models",
  DEVICE_MANAGEMENT: "device-management",
  FIELD_SERVICE_MANAGEMENT: "field-service-management",
  OPERATIONAL_DASHBOARD: "operational-dashboard",
  MANAGEMENT_DASHBOARD: "management-dashboard",
  ASSET_CONTRACT: "asset-contract",
  MONITORING_DASHBOARD: "monitoring-dashboard",
  GRID_MONITORING_APP: "grid-monitoring-app",
  ANALYTICS_DASHBOARD: "analytics-dashboard",
};

export const KEYCLOAK_CLIENT_SECRETS = {
  ASSET_SUITE: `${process.env.REACT_APP_SUITE_CLIENT_SECRET}`,
  ASSET_MANAGEMENT: "",
};

export const CLEAR_CLIENT_SCOPES = "clear-scopes";

export const SESSION_KEY = {
  NAVIGATION_STATE: "vdfwfznVgu",
  LOCAL_STORAGE_KEY: "ut",
  REDIRECT_LOCATION_KEY: "rl",
  CLEAR_ALL_TOKEN_DATA_KEY: "cl",
  SUITE_KEY: "su1te",
  MODELS_KEY: "mod3ls",
  ASSETS_KEY: "ass3ts",
  DEVICE_MANAGEMENET_KEY: "d3vice",
  FSM_KEY: "f2m",
  OPERATIONAL_DASHBOARD_KEY: "0pd",
  MANAGEMENT_DASHBOARD_KEY: "Wgmt",
};

export const MESSAGES = {
  SOMETHING_WENT_WRONG: "Something went wrong",
  LOGGED_OUT: "Logged out Successfully",
  USER_UNAUTHORIZED: "User unauthorized",

  MODEL_NAME_REQUIRED: "Please enter name",
  MODEL_DESC_REQUIRED: "Please add a description",
  MODEL_DESC_LENGTH:
    "The description should only contain charatcter limit 3 to 255",
  MODEL_NAME_LENGTH: "Model name should only contain charatcter limit 3 to 25",
  ICON_SIZE_EXCEEDS: "Please select a file less than 5MB",
  MODEL_NAME_PATTERN: "Model name should not contain special characters",
  MODEL_NAME_EXISTS: "Model name already exists. Please enter a new name",
  RESERVED_MODEL_NAME: "Reserved name cannot be used, please try a new name",
  TARGET_SHOULD_BE_A_ROOT_NODE: "Target model should be a root node",
  ROOT_NODE_CANNOT_BE_EDITED: "Root node cannot be updated",
  AGGREGATION_NOT_ALLOWED: "Aggregation not allowed.Please choose association",
  MODEL_ALREADY_EXIST_IN_ASSETS: "MODEL_ALREADY_EXIST_IN_ASSETS",
  MODEL_ALREADY_EXIST_IN_ASSETS_MESSAGE: "Model name already exist in assets",

  MODEL_NAME_EDGE_NAME_SHOULD_NOT_SAME:
    "Model name and relation name should not be same, please enter a new name",
  MODEL_CREATE_SUCCESS: "Model has been successfully created",
  MODEL_UPDATE_SUCCESS: "Model has been successfully updated",
  MODEL_DELETE_SUCCESS: "Model has been successfully deleted",
  MODEL_PUBLISH_SUCCESS: "Model has been successfully published",
  MODEL_RELATION_SUCCESS: "Model relation has been successfully created",
  MODEL_RELATION_EXISTS:
    "Model relation already exists. Please enter a new name",
  MODEL_HAS_MULTIPLE_RELATIONS_MAPPED: "MODEL_HAS_MULTIPLE_RELATIONS_MAPPED",
  MODEL_HAS_MULTIPLE_INSTANCES_MAPPED: "MODEL_HAS_MULTIPLE_INSTANCES_MAPPED",
  RESERVED_PROPERTY_NAME: "Reserved name cannot be used, please try a new name",
  MODEL_RELATION_DELETE_SUCCESS: "Model relation has been successfully deleted",
  STATIC_PROPERY_CREATE_SUCCESS: "Model property has been successfully created",
  STATIC_PROPERY_UPDATE_SUCCESS: "Model property has been successfully updated",
  STATIC_PROPERY_DELETE_SUCCESS: "Model property has been successfully deleted",
  PROPERTY_NAME_EXISTS: "Property name already exists. Please enter a new name",
  DYNAMIC_PROPERTY_CREATE_SUCCESS:
    "Model dynamic property has been successfully created",
  DYNAMIC_PROPERTY_UPDATE_SUCCESS: "Dynamic Property updated successfully",
  DYNAMIC_PROPERTY_DELETE_SUCCESS: "Dynamic property deleted successfully",
  FORMULA_NOT_VALID: "Formula not valid",
  DOWNLOAD_SUCCESS: "File has been downloaded successfully",
  PROPERTY_NAME_CANNOT_BE_EDITED: "name cannot be edited",
  PROPERTY_CANNOT_BE_DELETED: "Property cannot be deleted.Already in use",
  DOMAIN_PUBLISH_STATE_INPROGRESS: "DOMAIN_PUBLISH_STATE_INPROGRESS",
  DOMAIN_PUBLISH_STATE_INPROGRESS_MESSAGE: "Model publish in progress",

  FILE_SERVICE_DOWN: "File service is down",
  MIN_MAX_REQUIRED: "Min length and Max length value are required",
};

// //DATATYPES
export const Boolean = "BOOLEAN";
export const Short = "SHORT";
export const Integer = "INTEGER";
export const Long = "LONG";
export const Float = "FLOAT";
export const Double = "DOUBLE";
export const DateTime = "DATETIME";
export const String = "STRING";
export const Binary = "BINARY";
export const Embedded = "EMBEDDED";
export const List = "LIST";
export const Map = "MAP";
export const Link = "LINK";
export const Byte = "BYTE";
export const Decimal = "DECIMAL";

//model click position tags
export const CLICKPOSITION = {
  NONE: "NONE",
  SIDEBAR: "SIDEBAR",
  TREE: "TREE",
};

export const ASSET_REDIRECT_SCOPES = {
  ASSET_SUITE: "asset_suite",
  ASSET_MANAGEMENT: "asset_management",
  ASSET_MODELS: "asset_models",
  DEVICE_MANAGEMENT: "device_management",
  FIELD_SERVICE_MANAGEMENT: "field_service_management",
  OPERATIONAL_DASHBOARD: "operational_dashboard",
  MANAGEMENT_DASHBOARD: "management_dashboard",
  MONITORING_DASHBOARD: "monitoring_dashboard",
  GRID_MONITORING_APP: "grid_monitoring_app",
  ANALYTICS_DASHBOARD: "analytics_dashboard",
};
