import { default as axios, default as request } from "axios";
import {
  ASSET_MODEL_API,
  CLICKPOSITION,
  MESSAGES,
} from "config/default.config";
import {
  IExistinModelRelations,
  IExistingInstanceCount,
} from "modules/asset-model-crud/delete-model";
import { fileManagemntActions } from "store/file-management/file-management.action";
import { modelPropertyActions } from "store/model-properties/model-property.action";
import {
  setDynamicProperties,
  setSelectedTreeModelDynamicProps,
  setSelectedTreeModelName,
  setSelectedTreeModelStaticProps,
} from "store/model-properties/model-property.slice";
import { staticPropertyActions } from "store/model-properties/static-property.action";
import { seqrOpsActions } from "store/seqr-ops/seqr-ops.action";
import {
  setIsModelChange,
  setLoading,
  setNavigationState,
} from "store/seqr-ops/seqr-ops.slice";
import { toasterUtils } from "utils/toaster.utils";
import { AppThunk } from "..";
import {
  ICreateModelRelationReq,
  ICreateModelReq,
  IModelListItem,
} from "./asset-model.interface";
import { assetModelService } from "./asset-model.service";
import {
  setDomainDetails,
  setFilteredModels,
  setModelHierarchy,
  setModels,
  updateSelectedModel,
} from "./asset-model.slice";

const getAllModelsCount = async () => {
  try {
    const response = await assetModelService.fetchAllModelsCount();
    if (response.success) {
      return response.data;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

const getModelById = async (modelId: number) => {
  const response = await assetModelService.getModelById(modelId);
  if (response.success) {
    return response;
  } else {
    return null;
  }
};

const generateErrors = (errorCode: string, setError: any) => {
  let fieldName: string = "";
  let message: string = "";

  if (errorCode === "RELATION_EXISTS") {
    setError("relationshipName", {
      type: "custom",
      message: MESSAGES.MODEL_RELATION_EXISTS,
    });
  } else if (errorCode === "RESERVED_MODEL_NAME") {
    setError("relationshipName", {
      type: "custom",
      message: MESSAGES.RESERVED_MODEL_NAME,
    });
  } else if (errorCode === "TARGET_SHOULD_BE_A_ROOT_NODE") {
    setError("targetId", {
      type: "custom",
      message: MESSAGES.TARGET_SHOULD_BE_A_ROOT_NODE,
    });
  } else if (errorCode === "AGGREGATION_NOT_ALLOWED") {
    setError("isAssoc", {
      type: "custom",
      message: MESSAGES.AGGREGATION_NOT_ALLOWED,
    });
  }
  switch (errorCode) {
    case "MODEL_NAME_EXISTS":
      fieldName = "name";
      message = MESSAGES.MODEL_NAME_EXISTS;
      break;
    case "MODEL_NAME_EDGE_NAME_SHOULD_NOT_SAME":
      fieldName = "name";
      message = MESSAGES.MODEL_NAME_EDGE_NAME_SHOULD_NOT_SAME;
      break;
    case "RESERVED_MODEL_NAME":
      fieldName = "name";
      message = MESSAGES.RESERVED_MODEL_NAME;
      break;
    case "ROOT_NODE_CANNOT_BE_EDITED":
      fieldName = "isRootNode";
      message = MESSAGES.ROOT_NODE_CANNOT_BE_EDITED;
      break;
    case "MODEL_ALREADY_EXIST_IN_ASSETS":
      fieldName = "name";
      message = MESSAGES.MODEL_ALREADY_EXIST_IN_ASSETS_MESSAGE;
      break;

    default:
      break;
  }

  if (fieldName) {
    setError(fieldName, {
      type: "custom",
      message: message,
    });
  }
};

const createModel = (
  modelDetails: ICreateModelReq,
  fileData: FormData | any,
  setError: any,
  setSuccess: any,
  setIsDisableButton: any,
  setLoading: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      if (fileData !== undefined) {
        axios
          .get(ASSET_MODEL_API.REFERENCE_URL)
          .then(async (response) => {
            fileData?.append("reference", response.data.data || "");
            const fileDataResponse = await fileManagemntActions.uploadFile(
              fileData
            );
            if (fileDataResponse) {
              const url = fileDataResponse.url || "";
              // modelDetails.id = createResponse.data.id;
              modelDetails.icon = url;

              const createResponse = await assetModelService.createModel(
                modelDetails
              );

              if (createResponse.data.success) {
                toasterUtils.showSuccess(MESSAGES.MODEL_CREATE_SUCCESS);
                setSuccess(true);

                dispatch(getModelList(createResponse.data?.data?.id));
              } else if (!createResponse.data.success) {
                setIsDisableButton(false);
                dispatch(setLoading(false));
                const errorCode = createResponse?.data?.errorCode;
                generateErrors(errorCode, setError);
              }
            }
          })
          .catch((error) => {
            console.log("error", error.response);
            dispatch(setLoading(false));

            if (error.response) {
              toasterUtils.showError(error.response?.data?.errorCode);
            }
          });
      } else {
        const createResponse = await assetModelService.createModel(
          modelDetails
        );

        if (createResponse.data.success) {
          toasterUtils.showSuccess(MESSAGES.MODEL_CREATE_SUCCESS);
          setSuccess(true);

          dispatch(getModelList(createResponse.data?.data?.id));
        } else if (!createResponse.data.success) {
          setIsDisableButton(false);
          if (
            createResponse?.data?.errorCode ===
            MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS
          ) {
            toasterUtils.showError(
              MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS_MESSAGE
            );
          }
          const errorCode = createResponse?.data?.errorCode;
          generateErrors(errorCode, setError);
        }
        // modelDetails.icon = "";
        // modelDetails.id = createResponse.data.id;
        // const modelUpdateResponse = await assetModelService.updateModel(
        //   modelDetails
        // );
      }
      //   if (modelUpdateResponse) {
      //     toasterUtils.showSuccess(MESSAGES.MODEL_CREATE_SUCCESS);
      //     setSuccess(true);

      //     dispatch(getModelList(createResponse?.data?.id));
      //   }
      // }

      // }
    } catch (error: any) {
      console.log("error", error.request);
      console.log("error-message", error.message);

      if (error.response) {
        toasterUtils.showError(error.response?.data?.errorCode);
      }
    }
  };
};

const updateModel = (
  modelDetails: ICreateModelReq,
  setError: any,
  setSuccess: any,
  setIsDisableButton: any,
  setExistingInstanceCount: any,
  setLoading: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      let existingModelName: string = modelDetails.defaultName || "";
      let newModelName: string = modelDetails.name;
      const responseFromManagement =
        await assetModelService.checkIfModelInstanceExist(existingModelName);
      if (responseFromManagement.success) {
        const response2FromManagement =
          await assetModelService.checkIfModelInstanceExist(newModelName);
        if (response2FromManagement.success) {
          const response = await assetModelService.updateModel(modelDetails);
          if (response.data.success) {
            toasterUtils.showSuccess(MESSAGES.MODEL_UPDATE_SUCCESS);
            setSuccess(true);
            dispatch(getModelList(response.data?.data?.id));
          } else if (!response.data.success) {
            setIsDisableButton(false);
            dispatch(setLoading(false));
            const errorCode = response?.data?.errorCode;
            generateErrors(errorCode, setError);
          }
        } else {
          console.log(responseFromManagement);

          setIsDisableButton(false);
          dispatch(setLoading(false));
          if (
            responseFromManagement?.errorCode ===
            MESSAGES.MODEL_HAS_MULTIPLE_INSTANCES_MAPPED
          ) {
            let existingInstanceCount: IExistingInstanceCount = {
              modelName: newModelName,
              count: responseFromManagement?.data,
            };
            setExistingInstanceCount(existingInstanceCount);
          } else if (
            responseFromManagement?.errorCode ===
            MESSAGES.MODEL_ALREADY_EXIST_IN_ASSETS
          ) {
            const errorCode = responseFromManagement?.errorCode;
            generateErrors(errorCode, setError);
          }
        }
      } else {
        console.log(responseFromManagement);
        setIsDisableButton(false);
        dispatch(setLoading(false));
        if (
          responseFromManagement?.errorCode ===
          MESSAGES.MODEL_HAS_MULTIPLE_INSTANCES_MAPPED
        ) {
          let existingInstanceCount: IExistingInstanceCount = {
            modelName: existingModelName,
            count: responseFromManagement?.data,
          };
          setExistingInstanceCount(existingInstanceCount);
        }
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        dispatch(setLoading(false));
        const errorCode = error.response?.data?.errorCode;
        generateErrors(errorCode, setError);
      }
    }
  };
};

const createIconAndUpdateModel = (
  fileData: FormData,
  modelDetails: ICreateModelReq,
  setError: any,
  setSuccess: any,
  setIsDisableButton: any,
  setLoading: any
): AppThunk => {
  return async (dispatch, getState) => {
    // try {
    axios
      .get(ASSET_MODEL_API.REFERENCE_URL)
      .then(async (referenceResponse) => {
        fileData.append("reference", referenceResponse.data.data);
        const fileDataResponse = await fileManagemntActions.uploadFile(
          fileData
        );
        const url = fileDataResponse.url || "";
        modelDetails.icon = url;

        const response = await assetModelService.updateModel(modelDetails);
        if (response.data.success) {
          toasterUtils.showSuccess(MESSAGES.MODEL_UPDATE_SUCCESS);
          setSuccess(true);
          dispatch(getModelList(response.data?.data?.id));
        } else if (!response.data.success) {
          setIsDisableButton(false);
          dispatch(setLoading(false));
          const errorCode = response?.data?.errorCode;
          generateErrors(errorCode, setError);
        }
      })
      .catch((error) => {
        if (request.isAxiosError(error) && error.response) {
          dispatch(setLoading(false));

          toasterUtils.showError(error.response?.data?.errorCode);
        }
      });

    // } catch (error) {
    //   if (request.isAxiosError(error) && error.response) {
    //     toasterUtils.showError(error.response?.data?.errorCode);
    //   }
    // }
    return undefined;
  };
};

const deleteIconAndUpdateModel = (
  details: ICreateModelReq,
  setError: any,
  setSuccess: any,
  setIsDisableButton: any,
  setLoading: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const url = details.icon.toString();
      const filename = url.substring(url.lastIndexOf("/") + 1);
      await fileManagemntActions.deleteFile(filename);
      details.icon = "";
      const response = await assetModelService.updateModel(details);

      if (response.data.success) {
        toasterUtils.showSuccess(MESSAGES.MODEL_UPDATE_SUCCESS);
        setSuccess(true);
        dispatch(getModelList(response.data?.data?.id));
      } else if (!response.data.success) {
        if (
          response?.data?.errorCode === MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS
        ) {
          toasterUtils.showError(
            MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS_MESSAGE
          );
        }
        setIsDisableButton(false);
        dispatch(setLoading(false));
        const errorCode = response?.data?.errorCode;
        generateErrors(errorCode, setError);
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        dispatch(setLoading(false));

        toasterUtils.showError(error.response?.data?.errorCode);
      }
    }
    return undefined;
  };
};

const replaceIconAndUpdateModel = (
  fileData: FormData,
  details: ICreateModelReq,
  setError: any,
  setSuccess: any,
  setIsDisableButton: any,
  setLoading: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const fileDataResponse = await fileManagemntActions.replaceFile(fileData);
      const url = fileDataResponse.url || "";
      details.icon = url;

      const propertyUpdateUrlResponse = await assetModelService.updateModel(
        details
      );

      if (propertyUpdateUrlResponse.data.success) {
        toasterUtils.showSuccess(MESSAGES.MODEL_UPDATE_SUCCESS);
        setSuccess(true);
        dispatch(getModelList(propertyUpdateUrlResponse.data?.data?.id));
      } else if (!propertyUpdateUrlResponse.data.success) {
        setIsDisableButton(false);
        dispatch(setLoading(false));
        const errorCode = propertyUpdateUrlResponse?.data?.errorCode;
        generateErrors(errorCode, setError);
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        dispatch(setLoading(false));

        showError(error.response?.data?.errorCode, setError);
      }
    }
  };
};

const getModelList = (
  selectedModelId: number | undefined = undefined
): AppThunk => {
  return async (dispatch, getState) => {
    const response = await assetModelService.fetchAllModels();
    dispatch(setModels(response?.data || []));

    const navigationState = getState().seqrOps.navigationState;

    let selectedModel = response?.data?.[0];
    let sidebarShowAllModels = true;
    if (selectedModelId === undefined) {
      if (navigationState.selectedModelId) {
        selectedModel = response?.data?.filter(
          (x: { id: number }) => x.id === navigationState.selectedModelId
        )?.[0];
        console.log(navigationState);

        if (selectedModel?.isEquipment === true) {
          sidebarShowAllModels = navigationState.sidebarShowAllModels;
        }
      } else {
        selectedModel = response?.data?.filter(
          (x: { isEquipment: boolean }) => x.isEquipment === true
        )?.[0];
        sidebarShowAllModels = false;
      }
    } else if (selectedModelId) {
      selectedModel = response?.data?.filter(
        (x: { id: number }) => x.id === selectedModelId
      )?.[0];

      if (
        selectedModel?.isEquipment == true &&
        navigationState.sidebarShowAllModels === false
      ) {
        sidebarShowAllModels = false;
      }
    }

    dispatch(
      seqrOpsActions.updateNavigationState({
        ...navigationState,
        sidebarShowAllModels: sidebarShowAllModels,
        selectedModelId: selectedModel?.id || 0,
      })
    );
    dispatch(setIsModelChange(selectedModel?.id || 0));

    dispatch(updateSelectedModel(selectedModel || undefined));
    if (selectedModel?.id > 0) {
      dispatch(getModelHierarchy(selectedModel?.id, CLICKPOSITION.NONE));
    }
  };
};

const searchByModelName = (name: String): AppThunk => {
  return async (dispatch, getState) => {
    const rawAssetModels = getState().assetModels.rawModels;
    const rawSelectedModels = getState().assetModels.selectedModels;

    const filteredModels: IModelListItem[] = rawAssetModels.filter(
      (model: IModelListItem) => {
        return `${model.name}`.toLowerCase().includes(name.toLowerCase());
      }
    );

    const filteredSelectedModels: IModelListItem[] = rawSelectedModels.filter(
      (model: IModelListItem) => {
        return `${model.name}`.toLowerCase().includes(name.toLowerCase());
      }
    );
    dispatch(setFilteredModels({ filteredModels, filteredSelectedModels }));
  };
};

const getModelHierarchy = (
  modelId: number,
  clickPosition: string
): AppThunk => {
  return async (dispatch, getState) => {
    const response = await assetModelService.fetchModelHierarchy(modelId);
    if (
      clickPosition === CLICKPOSITION.SIDEBAR ||
      clickPosition === CLICKPOSITION.NONE
    ) {
      dispatch(setModelHierarchy(response?.data));
      dispatch(
        staticPropertyActions.getModelStaticProperties(modelId, clickPosition)
      );
      dispatch(
        modelPropertyActions.getModelDynamicProperty(modelId, clickPosition)
      );
    } else if (clickPosition === CLICKPOSITION.TREE) {
      dispatch(
        staticPropertyActions.getModelStaticProperties(modelId, clickPosition)
      );
      dispatch(
        modelPropertyActions.getModelDynamicProperty(modelId, clickPosition)
      );
    }
  };
};

const getSubModelHierarchy = async (modelId: number): Promise<AppThunk> => {
  const response = await assetModelService.fetchModelHierarchy(modelId);
  return response?.data?.[0]?.edges || [];
};

const setSelectedModel = (modelId: number, clickPosition: string): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedModel = state.assetModels.rawModels.filter(
      (x) => x.id === modelId
    );
    if (
      clickPosition === CLICKPOSITION.SIDEBAR ||
      clickPosition === CLICKPOSITION.NONE
    ) {
      dispatch(setSelectedTreeModelStaticProps([]));
      dispatch(setSelectedTreeModelName(""));
      dispatch(setSelectedTreeModelDynamicProps([]));
      dispatch(updateSelectedModel(selectedModel?.[0] || undefined));
      dispatch(
        setNavigationState({
          ...state.seqrOps.navigationState,
          selectedModelId: selectedModel?.[0]?.id || 0,
        })
      );

      if (modelId !== state.seqrOps.navigationState.selectedModelId) {
        dispatch(getModelHierarchy(modelId, clickPosition));
      }
    } else if (clickPosition === CLICKPOSITION.TREE) {
      dispatch(setSelectedTreeModelDynamicProps([]));
      dispatch(setDynamicProperties([]));
      dispatch(getModelHierarchy(modelId, clickPosition));
    }
  };
};

const deleteModel = (
  icon: string | FileList,
  modelId: number,
  setSuccess: (state: boolean) => void,
  setExistingModelRelations: any,
  setExistingInstanceCount: any
): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      if (icon !== "") {
        const url = icon.toString();
        const reference = url.substring(url.lastIndexOf("/") + 1);
        await fileManagemntActions.deleteFile(reference);
        const response = await assetModelService.deleteModel(modelId);
        const selectedModel = state.assetModels.rawModels.filter(
          (x) => x.id === modelId
        );
        if (response.data.success) {
          toasterUtils.showSuccess(MESSAGES.MODEL_DELETE_SUCCESS);
          setSuccess(true);
          dispatch(getModelList());
        }
      } else {
        const initialResponse =
          await assetModelService.checkIfModelRelationsExist(modelId);

        if (initialResponse.success) {
          let modelName = initialResponse.data;
          const responseFromManagement =
            await assetModelService.checkIfModelInstanceExist(modelName);

          if (responseFromManagement.success) {
            const response = await assetModelService.deleteModel(modelId);
            if (response.success) {
              const selectedModel = state.assetModels.rawModels.filter(
                (x) => x.id === modelId
              );
              let index = state.assetModels.rawModels.indexOf(selectedModel[0]);
              const selectedModelNew = state.assetModels.rawModels.filter(
                (x) => state.assetModels.rawModels.indexOf(x) === index + 1
              )[0];

              toasterUtils.showSuccess(MESSAGES.MODEL_DELETE_SUCCESS);
              setSuccess(true);
              if (selectedModelNew != undefined) {
                dispatch(getModelList(selectedModelNew.id));
              } else {
                dispatch(getModelList(state.assetModels.rawModels[0].id));
              }
            }
          } else {
            dispatch(setLoading(false));
            if (
              responseFromManagement?.errorCode ===
              MESSAGES.MODEL_HAS_MULTIPLE_INSTANCES_MAPPED
            ) {
              let existingInstanceCount: IExistingInstanceCount = {
                modelName: modelName,
                count: responseFromManagement?.data,
              };
              setExistingInstanceCount(existingInstanceCount);
            }
          }
        } else {
          dispatch(setLoading(false));
          if (
            initialResponse.errorCode ===
            MESSAGES.MODEL_HAS_MULTIPLE_RELATIONS_MAPPED
          ) {
            let existingModelRelation: IExistinModelRelations = {
              sourceModelRelations: initialResponse?.data?.sourceModelRelations,
              targetModelRelations: initialResponse?.data?.targetModelRelations,
              modelName: initialResponse?.data?.modelName,
            };
            setExistingModelRelations(existingModelRelation);
          }
        }
      }
    } catch (error: any) {
      if (request.isAxiosError(error) && error?.response) {
        toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
      }
    }
  };
};

const createRelation = (
  relation: ICreateModelRelationReq,
  setError: any,
  setSuccess: any,
  setIsDisableButton: any,
  setLoading: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await assetModelService.createRelation(relation);
      if (response.data.success) {
        toasterUtils.showSuccess(MESSAGES.MODEL_RELATION_SUCCESS);
        setSuccess(true);
        dispatch(getModelList(response.data?.data?.sourceId));
      } else if (!response.data.success) {
        if (
          response?.data?.errorCode === MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS
        ) {
          toasterUtils.showError(
            MESSAGES.DOMAIN_PUBLISH_STATE_INPROGRESS_MESSAGE
          );
        } else {
          const errorCode = response?.data?.errorCode;
          generateErrors(errorCode, setError);
        }
        setIsDisableButton(false);
        dispatch(setLoading(false));
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const errorCode = error.response?.data?.errorCode;
        if (errorCode === "RELATION_EXISTS") {
          setIsDisableButton(false);
          dispatch(setLoading(false));
          setError("relationshipName", {
            type: "custom",
            message: MESSAGES.MODEL_RELATION_EXISTS,
          });
        } else if (errorCode === "RESERVED_MODEL_NAME") {
          setIsDisableButton(false);
          dispatch(setLoading(false));
          setError("relationshipName", {
            type: "custom",
            message: MESSAGES.RESERVED_MODEL_NAME,
          });
        } else if (errorCode === "TARGET_SHOULD_BE_A_ROOT_NODE") {
          setIsDisableButton(false);
          dispatch(setLoading(false));
          setError("targetId", {
            type: "custom",
            message: MESSAGES.TARGET_SHOULD_BE_A_ROOT_NODE,
          });
        } else if (errorCode === "AGGREGATION_NOT_ALLOWED") {
          setIsDisableButton(false);
          dispatch(setLoading(false));
          setError("isAssoc", {
            type: "custom",
            message: MESSAGES.AGGREGATION_NOT_ALLOWED,
          });
        }
      } else {
        setIsDisableButton(false);
        dispatch(setLoading(false));
        setError("relationshipName", {
          type: "custom",
          message: MESSAGES.MODEL_RELATION_EXISTS,
        });
      }
    }
  };
};

const publishModel = (domainId: number): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const response = await assetModelService.publishModel(domainId);

      if (response?.data?.success) {
        dispatch(setLoading(false));
        toasterUtils.showSuccess(MESSAGES.MODEL_PUBLISH_SUCCESS);
      } else {
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (request.isAxiosError(error) && error.response) {
        toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
      }
    }
    dispatch(assetModelActions.getDomainDetails());
  };
};

const deleteRelation = (
  relationId: number,
  sourceId: number,
  setSuccess: (state: boolean) => void
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await assetModelService.deleteRelation(relationId);
      if (response.success) {
        toasterUtils.showSuccess(MESSAGES.MODEL_RELATION_DELETE_SUCCESS);
        setSuccess(true);
        dispatch(getModelList(sourceId));
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
      }
    }
  };
};

const showError = (errorCode: string, setError: any) => {
  let errorMessage: string = "";
  let fieldName: string = "";
  switch (errorCode) {
    case "NAME_FIELD_REQUIRED":
      fieldName = "name";
      errorMessage = "Please enter name";
      break;
    case "RESERVED_PROPERTY_NAME":
      fieldName = "name";
      errorMessage = MESSAGES.RESERVED_PROPERTY_NAME;
      break;
    case "PROPERTY_NAME_EXISTS":
      fieldName = "name";
      errorMessage = MESSAGES.PROPERTY_NAME_EXISTS;
      break;

    default:
      break;
  }

  if (fieldName) {
    setError(fieldName, {
      type: "custom",
      message: errorMessage,
    });
  }
};

const setDomain = (domainId: number): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await assetModelService.setDomain(domainId);

      if (response?.data?.success) {
        toasterUtils.showSuccess("SUCCESS");
      } else {
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (request.isAxiosError(error) && error.response) {
        toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
      }
    }
  };
};

const getDomainDetails = (): AppThunk => {
  return async (dispatch, getState) => {
    const response = await assetModelService.getDomainDetails();
    dispatch(setDomainDetails(response?.data || null));
  };
};

const getPublishHistory = (domainId: number): AppThunk => {
  return async (dispatch, getState) => {
    const response = await assetModelService.getPublishHistory(domainId);
    // dispatch(setDomainDetails(response?.data || null));
  };
};

export const assetModelActions = {
  getDomainDetails,
  getModelById,
  getAllModelsCount,
  createModel,
  updateModel,
  getPublishHistory,
  getModelList,
  searchByModelName,
  getModelHierarchy,
  getSubModelHierarchy,
  setSelectedModel,
  deleteModel,
  createRelation,
  publishModel,
  deleteRelation,
  createIconAndUpdateModel,
  deleteIconAndUpdateModel,
  replaceIconAndUpdateModel,
  setDomain,
};
